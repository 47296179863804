<template>
	<div class="chip-item">
		<p>{{chipname}}</p>
		<p @click="$emit('remove')" class="chip-remove">
			<b-icon class="overlay-close-icon" icon="x"></b-icon>
		</p>
	</div>
</template>

<script>
export default {
	name: "Chip",
	components: {},
	props: {
		chipname: String,
		chiptype: String,
		chipid: Number
	},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.chip-item {
	display: flex;
	font-size: 12px;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	margin: 8px 20px;
	border: 1px solid #CCC;
	background-color: #EEE;
	transition: background-color 0.3s ease-in-out;
}
.chip-item:hover {
	background-color: #DDD;
}
.chip-remove {
	cursor: pointer;
}
.chip-remove:hover {
	color: #C00;
}
</style>