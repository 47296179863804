<template>
	<div class="main-section">
		<NavMenu></NavMenu>

		<div class="content-container below-navbars">
			<h1>Select a report type</h1>
			<div id="report-card-holder">
				<div v-for="rep in reportObjects" :key="rep.id" class="report-card" :title="rep.tooltip" @click="$router.push('/music-reports/'+rep.reporturl)">
					<div class="card-body">
						<div class="icon-holder"><i class="fa" :class="rep.facode"></i></div>
						<p class="card-title">
							{{rep.title}}
							<span class='isnew' v-if="rep.marknew">New!</span>
							<span class='isnew' v-if="rep.markimproved">Improved!</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NavMenu from "@/components/NavMenu";
export default {
	name: "MusicHome",
	components: {
		NavMenu
	},
	props: {},
	data: function () {
		return {

		}
	},
	computed: {
		reportObjects() {
			if(this.$store.state.user.isAdmin) {
				return this.$store.state.user.allowedReportObjects;
			}
			return this.$store.state.user.allowedReportObjects.filter(item => item.devonly === false);
		}
	},
	methods: {

	},
	watch: {

	},
	mounted() {
		this.checkFetchUserAppData(); //triggers fetch items from kev db for usage in front end for user
		document.title = "Tracka - Music Reports";
	}
}
</script>

<style scoped>
.isnew {
	color: #574099;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	display: block;
	margin-top: 5px;
}
#report-card-holder {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 30px;
}
#report-card-holder .report-card {
	cursor: pointer;
	padding-left: 0;
	box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
	margin: 8px;
	width: calc(25% - 16px);
	border-radius: 8px;
	background-color: #FFF;
	height: 260px;
	display: flex;
	align-items: center;
}
#report-card-holder .report-card:hover {
	background: #574099;
	color: #fff !important;
	transition: ease-in-out 0.5s;
}
#report-card-holder .card-body {
	text-align: center;
	margin: 30px 0;
}
.report-card .card-title {
	font-size: 16px;
	font-weight: 500;
	font-family: 'Quicksand', sans-serif;
}
.icon-holder {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}
.report-card .fa {
	font-size: 40px;
	color: #F38230;
	background: #fff;
	border-radius: 50%;
	height: 100px;
	width: 100px;
	border: 2px solid #F38230;
	transition: ease-in-out 0.5s;
	display: flex;
	align-items: center;
	justify-content: center;
}
.report-card:hover .fa {
	background: #fff;
	color: #942C8D;
	border-color: #574099;
}
</style>