<template>
	<div id="app">

		<LoaderBlanket></LoaderBlanket>

		<AlertTop v-bind:obj="$store.state.kalert"></AlertTop>

		<router-view v-if="$store.state.sesscheckcomplete"></router-view>

	</div>
</template>

<script>
import axios from "axios";
import AlertTop from "@/components/AlertTop";
import LoaderBlanket from "@/components/LoaderBlanket";

export default {
	name: 'App',
	components: {
		LoaderBlanket,
		AlertTop,
	},
	data: function () {
		return {
			requestedRoute: '',
		}
	},
	methods: {
		sesscheck() {
			let self = this;
			let formdata = {
				getparams: this.$store.state.getparams,
				requestedroute: this.requestedRoute,
			};
			axios.post(
				"/post/firstrun.php", formdata
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.objects && ret.objects.user) {
					self.$store.commit("setUser", ret.objects.user);
				}
				if(ret.sessionok !== 1) { //transfer to login screen
					if(self.$router.currentRoute.path !== '/login') self.$router.push('/login');
				}
				else {
					if(self.$router.currentRoute.path === '/') self.$router.push('/music');
				}
				self.$store.commit('setSesscheckComplete', true);
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	mounted() {
		this.requestedRoute = this.$router.currentRoute.path;
		this.sesscheck();
	},
	created() {
		//handle incoming GET parameters
		let urlparams = this.$route.query;
		this.$store.commit('setGetParams', urlparams);
		// if(urlparams.e !== undefined) {
		// 	this.$store.commit('setRegistrationEmail', urlparams.e);
		// }
	},
	watch: {
		$route() {
			//force close alert on page change
			this.$store.commit('closeKalert');
		}
	},
}
</script>

<style>
#app {
	background-color: #f6f8f9;
}
</style>
