<template>
	<div>
		<span v-if="dataitem.value">{{dataitem.value.songItem.artist}} - {{dataitem.value.songItem.title}} ({{dataitem.value.songItem.year}})</span>
		<div class="icon-holder" v-if="dataitem.value && dataitem.value.isTOH !== true">
			<i class="fa fa-clock cursorPointer rowIcon" @click="$emit('drillsong')" :title="'Click to view other '+getStationNameFromId(dataitem.value.stationId)+' play times'"></i>
			<i class="fa fa-building cursorPointer rowIcon" @click="$emit('drillmkt')" title="Click to view market plays"></i>
			<i v-if="$store.state.user.isAdmin !== 1" class="fa fa-angry cursorPointer rowIcon" @click="showBugReporter(dataitem.value);" title="Report an error with this item"></i>
			<i v-if="$store.state.user.isAdmin === 1" class="fa fa-angry cursorPointer rowIcon" @click="showSongEditCard(dataitem.value.songItem.trackaSongId);" title="Edit song details"></i>
		</div>
	</div>
</template>

<script>
export default {
	name: "MarketSideBySideSongCell",
	components: {},
	props: {
		dataitem: Object
	},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>