<template>
	<div>
		<div style="display: flex;">
			<p class="admintitle">Song Bugs</p>
		</div>

		<b-input type="text" v-model="searchtext" class="adminsearchbox"></b-input>

		<div class="admin-structure">

			<div class="admin-listview">
				<div v-for="li in shownObjects" :key="li.ai"
					class="admin-list-row" :class="{highlighted : activeItemId === li.ai}"
					@click="setActiveItem(li.ai)"
				>
					<span>{{li.existingInfo}}</span>
				</div>
			</div>
			<div class="admin-infoview">
				<div v-if="activeItem">
					<div class="admin-info-section" style="display: flex; justify-content: space-between; align-items: center;">
						<span class="admin-item-title">{{activeItem.existingInfo}}</span>
						<ActionButton buttontext="Mark Resolved" v-on:clicked="markBugComplete" :isworking="saveDetailsWorking"></ActionButton>
					</div>
					<div class="admin-tabs-section">
						<span class="admin-tab" @click="activesubtab = 'meta'" :class="{active : activesubtab === 'meta'}">Bug Info</span>
					</div>
					<div v-if="activesubtab === 'meta'">
						<div class="admin-info-section info-data">
							<p><span class="item-label">Tracka ID:</span> {{activeItem.trackaId}}</p>
							<p><span class="item-label">Existing Info:</span> {{activeItem.existingInfo}}</p>
							<p><span class="item-label">User:</span> {{activeItem.uid}}</p>
							<p><span class="item-label">Error Type:</span> {{activeItem.errType}}</p>
							<p><span class="item-label">User Notes:</span> {{activeItem.userNotes}}</p>
							<p><span class="item-label">Submitted:</span> {{activeItem.timestamp}}</p>
							<!--							<p><span class="item-label">Query Used:</span> {{activeItem.query_string}}</p>-->
						</div>
						<SongEditCard :trackaid="activeItem.trackaId"></SongEditCard>
					</div>

				</div>
			</div>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import ActionButton from "@/components/ActionButton";
import SongEditCard from "@/components/admin/SongEditCard.vue";

export default {
	name: "SongBugs",
	components: {
		SongEditCard,
		ActionButton
	},
	props: {},
	data: function () {
		return {
			searchtext: '',
			activeItemId: 0,
			activesubtab: 'meta',

			showInactive: false,

			saveDetailsWorking: false,
		}
	},
	computed: {
		listObjects() {
			return this.$store.state.songbugsAdminList
		},
		shownObjects() {
			if (this.searchtext.length > 0) {
				let src = this.searchtext.toLowerCase();
				return this.listObjects.filter(item =>
					(item.existingInfo.toLowerCase().includes(src))
				);
			}
			return this.listObjects //return all if limit not set
		},
		activeItem() {
			return this.$store.state.songbugsAdminList.find(item => item.ai === this.activeItemId)
		},
	},
	methods: {
		setActiveItem(id) {
			this.activeItemId = id
			//this.getSongData(this.activeItem.trackaId)
		},
		markBugComplete() {
			if(this.saveDetailsWorking === false) {
				this.saveDetailsWorking = true
				let form = {}
				form.bugai = this.activeItemId
				let self = this
				let targ = '/post/admin/mark-songbug-complete.php'
				axios.post(targ, form).then(function (response) {
					let ret = response.data; //response.data is returned info
					self.$store.commit('setFromAdminReturns', ret)
					self.saveDetailsWorking = false
				}).catch(function (error) {
					console.log(error)
					self.saveDetailsWorking = false
				});
			}
		},
	},
	watch: {
		listObjects() {
			if(this.activeItemId === 0) {
				this.setActiveItem(this.listObjects[0].ai)
			}
			else {
				let myitems = this.$store.state.songbugsAdminList.filter(item => item.ai === this.activeItemId);
				if(myitems.length === 0) { //we don't have a match - choose the next one at the top
					this.setActiveItem(this.listObjects[0].ai)
				}
			}
		},
		activeItemId() {

		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>