<template>
	<div class="autocompleter">
		<div class="inputholder">
			<b-form-input type="text" v-model="selectedText" @focus="dropdownshown = true" @blur="sendupdate"></b-form-input>
		</div>
		<div class="dropdown" v-if="dropdownshown">
			<div class="dropdown-item" v-for="s in dropdownoptions" :key="s[idfldname]" @click="selectme(s)">{{s[textfldname]}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Autocompleter",
	props: {
		searchlist: Array,
		incomingval: Number,
		idfldname: String,
		textfldname: String,
	},
	data: function() {
		return {
			selectedId: 0,
			selectedText: '',
			dropdownshown: false,
		}
	},
	computed: {
		dropdownoptions() {
			return this.searchlist.filter(item => item[this.textfldname].toLowerCase().includes(this.selectedText.toLowerCase()));
		},
	},
	methods: {
		selectme(item) {
			this.selectedId = item[this.idfldname];
			this.selectedText = item[this.textfldname];
			this.sendupdate();
			this.dropdownshown = false;
		},
		sendupdate() {
			var ob = {};
			ob[this.idfldname] = this.selectedId;
			ob[this.textfldname] = this.selectedText;
			this.$emit('update', ob);
			setTimeout(function() {
				this.dropdownshown = false;
			}.bind(this), 100);
		},
		doPrepare() {
			if(this.incomingval > 0) {
				this.selectedId = this.incomingval;
				var myob = this.searchlist.find(item => item[this.idfldname] === this.incomingval);
				this.selectedText = myob[this.textfldname];
			}
		},
	},
	watch: {
		selectedText() {
			if(this.selectedText.length < 2) { //clear selection
				this.selectedId = 0;
			}
		},
		incomingval() {
			this.doPrepare();
		},
	},
	mounted() {
		this.doPrepare();
	},
}
</script>

<style scoped>
.inputholder {
	margin-right: 10px;
}
.dropdown {
	background-color: #FAFAFA;
	border: 1px solid #DDD;
	position: absolute;
	min-width: 300px;
}
.dropdown-item {
	padding: 10px 10px;
	font-size: 14px;
	border-bottom: 1px solid #DDD;
	cursor: pointer;
}
</style>