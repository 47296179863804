<template>
	<div id="loadholdblanket" v-if="$store.state.loaderShown">
		<div id="loadhold">
			<img src="@/assets/loader.gif" alt="Loading" />
			<br/>
		</div>
	</div>
</template>

<script>
export default {
	name: "LoaderBlanket",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
#loadholdblanket {
	background:rgba(0,0,0,0.6);
	z-index: 9999;
	height: 100%;
	width: 100%;
	position: fixed;
}
#loadhold {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 50px auto;
	padding: 40px 50px;
	background: #FFF;
	border-radius: 10px;
	width: 200px;
}
</style>