<template>
	<div class="">
		<div class="content-container">
			<AudioPlayer :audioitem="playerAudioItem" :selections="selections"></AudioPlayer>
		</div>
		<DrilldownHolder v-if="drilldownOverlayShow" :drilldownreporttype="drilldownReportType"
			:drilldownitem="drilldownitem" v-on:closedrilldown="drilldownOverlayShow = false"
			:selections="selections"
		></DrilldownHolder>
		<div v-if="started" class="content-container report-section" :class="{sectionhide : drilldownOverlayShow === true}">
			<div class="report-top">
				<div class="headingHold">
					<h2 id="resultsHeading">
						{{heading}}
					</h2>
				</div>

				<div style="width: 300px; display: flex; align-items: center">
					<b-form-input
							id="filter-input"
							v-model="filter"
							type="search"
							placeholder="Type to Search"
							style="font-size: 13px;"
					></b-form-input>
					<div class="icon-excel-hold" v-if="returndata.length > 0" @click="toExcel" title="Download report to Excel file"><i class="fa fa-file-excel-o"></i></div>
				</div>
			</div>

			<div v-if="fetching === false" class="toggle-col-list-hold headingHold" style="margin-bottom: 10px;">
				<span style="font-weight:bold; font-size: 11px; margin-top:-15px; margin-right:5px;">Toggle Columns:</span>
				<span v-for="st in stationcols" :key="st.ind" @click="toggleColId(st.ind)" class="toggle-vis" :class="{'toggle-vis-inactive' : hidecolids.indexOf(st.ind) >= 0}">{{st.name}}</span>
			</div>

			<div v-if="fetching === false" style="overflow-x: auto; overflow-y: auto;">
				<b-table sticky-header="600px" :fields="tablefields" :items="returndata" :tbody-tr-class="rowClass" :filter="filter">
					<template #cell(station0)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
					<template #cell(station1)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
					<template #cell(station2)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
					<template #cell(station3)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
					<template #cell(station4)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
					<template #cell(station5)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
					<template #cell(station6)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
					<template #cell(station7)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
					<template #cell(station8)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
					<template #cell(station9)="data">
						<MarketSideBySideSongCell :dataitem="data" v-on:drillsong="drillDown('SongSpins', data.value);" v-on:drillmkt="drillDown('SongSpinsMarketSummary', data.value);"></MarketSideBySideSongCell>
					</template>
				</b-table>
			</div>

		</div>
		<BugReporter v-if="$store.state.bugreportshown"></BugReporter>
		<SongEditOverlay v-if="$store.state.songEditCardShown"></SongEditOverlay>
	</div>
</template>

<script>
import axios from "axios";
import AudioPlayer from "@/components/AudioPlayer";
import BugReporter from "@/components/BugReporter";
import DrilldownHolder from "@/components/DrilldownHolder";
import MarketSideBySideSongCell from "@/components/MarketSideBySideSongCell";
import SongEditOverlay from "@/components/SongEditOverlay.vue";
export default {
	name: "MarketSideBySideLog",
	components: {
		SongEditOverlay,
		MarketSideBySideSongCell,
		DrilldownHolder,
		BugReporter,
		AudioPlayer,
	},
	props: {
		selections: Object,
	},
	data: function () {
		return {
			showSelects: {
				market: true,
				brand: false,
				period: false,
				property: false,
				daypart: false,
				logdate: true,
				startdate: false,
				enddate: false,
			},
			reportType: 'MarketSideBySideLog', //for API URL
			fetching: false,
			started: false,
			reportTitle: 'Market Log Comparison',

			showShortPlays: false,
			heading: '',
			drilldownOverlayShow: false,
			drilldownReportType: '',
			drilldownitem: null,

			returndata: [],
			fieldsInit: [
				{key: 'num', label: 'Num'}
			],
			fields: [],
			filter: '',
			playerAudioItem: null,
			stationcols: [],
			hidecolids: [],
		}
	},
	computed: {
		tablefields() {
			if(this.hidecolids.length === 0) {
				return this.fields;
			}
			else {
				var flds = [];
				for(var i=0; i<this.fields.length; i++) {
					var fldnm = this.fields[i].key;
					if(fldnm.indexOf("station") === 0) {
						var fldid = parseInt(fldnm.replace("station", ""));
						if(this.hidecolids.indexOf(fldid) === -1) {
							flds.push(this.fields[i]);
						}
					}
					else {
						flds.push(this.fields[i]);
					}
				}
				return flds;
			}
		}
	},
	methods: {
		toggleColId(ind) {
			if(this.hidecolids.indexOf(ind) === -1) {
				this.hidecolids.push(ind);
			}
			else {
				this.hidecolids.splice(this.hidecolids.indexOf(ind), 1);
			}
		},
		drillDown(reporttype, item) {
			this.drilldownReportType = reporttype;
			this.drilldownitem = item;
			this.drilldownOverlayShow = true;
		},
		getAndPlayAudio(item) {
			this.playerAudioItem = item;
		},
		rowClass(item, type) {
			var str = 'trow';
			if (!item || type !== 'row') return
			if (item.isTOH === true) str += ' tohrow';
			return str;
		},
		getReport() {
			if(this.fetching === false) {
				this.drilldownOverlayShow = false; //force hide the drilldown reports
				if(this.selections.marketid === null || this.selections.logdate === null) {
					return false;
				}
				let self = this;
				let parstr = "marketId="+ this.selections.marketid+"&logDate=" + this.selections.logdate;
				let targ = '/api-web/market-side-by-side-log.php?'+parstr;

				this.$store.commit('setLastQueryUrl', targ);
				this.fetching = true;
				document.title = "Fetching data...";
				axios.get(targ).then(function(response) {
					let ret = response.data; //response.data is returned info
					document.title = 'Tracka - '+self.reportTitle;
					self.fields = [];
					for(let fi=0; fi<self.fieldsInit.length; fi++) {
						self.fields.push(self.fieldsInit[fi]);
					}
					let stations = []
					for(let s=0; s<ret.stations.length; s++) {
						let stfldn = 'station'+s;
						self.fields.push({key: stfldn, label: self.getStationNameFromId(ret.stations[s])});
						stations.push({ind: s, name: self.getStationNameFromId(ret.stations[s]), id: ret.stations[s]});
					}
					self.stationcols = stations;
					self.returndata = ret.tracks
					self.fetching = false;
					self.started = true; //first one has come back
					self.heading = "Market Log Comparison";
					self.sendLoggingInfo(self.reportType, self.selections);
				}).catch(function(error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
		newTOH(hr) {
			return {
				num: hr + ':00:00 Hr',
				validPlay: "Y",
				isTOH: true,
			};
		},
		toExcel() {
			var form = {};
			form.rows = this.prepareExcelTableRows(this.fields, this.returndata);
			form.layout = this.reportType;
			form.filename = this.getMarketFromId(this.selections.marketid).replace("/","");
			var targ = '/post/get-excel.php';
			axios.post(targ, form).then(function (response) {
				window.location = response.data;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		fetching() {
			this.$store.commit('showLoader', this.fetching);
		}
	},
	mounted() {
		this.checkFetchUserAppData(); //triggers fetch items from kev db for usage in front end for user
		this.$store.commit('setMusicDropdownItems', this.showSelects);
		document.title = 'Tracka - '+this.reportTitle;
	}
}
</script>

<style scoped>
.toggle-vis {
	font-size: 11px;
	margin-right: 5px;
	color: #000;
	cursor: pointer;
}
.toggle-vis-inactive {
	font-style: italic;
	color: #888;
}

</style>