<template>
	<div id="errorblanket">
		<div id="error-box-hold">
			<div id="error-box">
				<div id="error-box-top">
					<p class="error-box-heading">Update Song Record</p>
					<div class="error-exit" @click="closeOverlay">
						<i class="fa fa-times"></i>
					</div>
				</div>
				<SongEditCard :trackaid="$store.state.songEditTrackaId"></SongEditCard>
			</div>
		</div>
	</div>
</template>

<script>
import SongEditCard from "@/components/admin/SongEditCard.vue";

export default {
	name: "SongEditOverlay",
	components: {
		SongEditCard
	},
	props: {

	},
	data: function () {
		return {
			usernotes: '',
			errorType: null,
		}
	},
	computed: {

	},
	methods: {
		closeOverlay() {
			this.$store.state.songEditCardShown = false;
			this.$store.state.songEditTrackaId = '';
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
#errorblanket {
	background:rgba(0,0,0,0.6);
	z-index: 9999;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
}
#error-box-hold {
	text-align: left;
	margin: 50px auto;
	padding: 0;
	background: #FFF;
	width: 500px;
}
#error-box-top {
	background: #574099;
	color: #FFF;
	padding: 8px 15px;
	position: relative;
}
#error-form-hold {
	padding: 20px 50px 40px;
	font-size: 14px;
}
#error-form-hold p {
	margin-bottom: 5px;
	line-height: 1.5;
}
#error-form-hold label {
	margin-left: 5px;
	line-height: 1.5;
}
.error-box-heading {
	color: #FFF;
	margin: 0;
}
.error-exit {
	position: absolute;
	top: 8px;
	right: 10px;
	color: #FFF;
	cursor: pointer;
}
.error-exit:hover i {
	color: #CCC;
}
#error-user-notes {
	width: 100%;
	height: 100px;
	margin-bottom: 10px;
	font-family: arial, Sans-Serif;
	padding: 5px;
}
.errbtn {
	border: none;
	padding: 10px 20px;
	color: #FFF;
	background: #574099;
	font-size: 16px;
	cursor: pointer;
	margin-right: 15px;
	transition: opacity 0.3s ease-in-out;
}
.errbtn-cancel {
	background: #888;
}
.errbtn:hover {
	opacity: 0.9;
}
.custom-control {
	display: flex;
	align-items: center;
}

</style>