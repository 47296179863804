<template>
	<div class="content-container report-section">
		<div id="resultsBackBtn" @click="goBack">
			<i class="fa fa-chevron-left"></i> Back
		</div>
		<AudioPlayer :audioitem="playerAudioItem" :selections="selections"></AudioPlayer>

		<DrilldownSongSpins v-if="drilldownreporttypelive === 'SongSpins'" :item="drilldownitemlive" :selections="selections"
			v-on:playaudioitem="getAndPlayAudio($event)" v-on:switchdrilldown="switchDrilldown($event)"
		></DrilldownSongSpins>

		<DrilldownSongSpinsMarketSummary v-if="drilldownreporttypelive === 'SongSpinsMarketSummary'" :item="drilldownitemlive"
			v-on:playaudioitem="getAndPlayAudio($event)" v-on:switchdrilldown="switchDrilldown($event)" :selections="selections"
		></DrilldownSongSpinsMarketSummary>

		<StationFingerprintDrilldown v-if="drilldownreporttypelive === 'StationFingerprintDrilldown'" :item="drilldownitemlive"
			v-on:switchdrilldown="switchDrilldown($event)" :selections="selections" :drilldownpropname="this.drilldownpropname"
			:fingerprintreturndata="fingerprintreturndata"
		></StationFingerprintDrilldown>

		<StationFingerprintSongList v-if="drilldownreporttypelive === 'StationFingerprintSongList'" :item="drilldownitemlive"
			v-on:switchdrilldown="switchDrilldown($event)" :selections="selections" :fingerprintreturndata="fingerprintreturndata"
		></StationFingerprintSongList>

		<StationFingerprintUniverse v-if="drilldownreporttypelive === 'StationFingerprintUniverse'" :item="drilldownitemlive"
			v-on:switchdrilldown="switchDrilldown($event)" :selections="selections" :fingerprintreturndata="fingerprintreturndata"
		></StationFingerprintUniverse>


	</div>
</template>

<script>
import DrilldownSongSpins from "@/components/music-report-views/DrilldownSongSpins";
import AudioPlayer from "@/components/AudioPlayer";
import DrilldownSongSpinsMarketSummary from "@/components/music-report-views/DrilldownSongSpinsMarketSummary";
import StationFingerprintDrilldown from "@/components/music-report-views/StationFingerprintDrilldown";
import StationFingerprintSongList from "@/components/music-report-views/StationFingerprintSongList";
import StationFingerprintUniverse from "@/components/music-report-views/StationFingerprintUniverse";
export default {
	name: "DrilldownHolder",
	components: {
		StationFingerprintUniverse,
		StationFingerprintSongList,
		StationFingerprintDrilldown,
		DrilldownSongSpinsMarketSummary,
		AudioPlayer,
		DrilldownSongSpins
	},
	props: {
		drilldownreporttype: String,
		drilldownitem: Object,
		selections: Object,
		drilldownpropname: String,
		fingerprintreturndata: Object,
	},
	data: function () {
		return {
			playerAudioItem: null,
			drilldownitemlive: null,
			drilldownreporttypelive: '',
			drillHistory: [],
			goingBack: false,
		}
	},
	computed: {},
	methods: {
		getAndPlayAudio(item) {
			this.playerAudioItem = item;
		},
		switchDrilldown(newinfo) {
			this.drilldownreporttypelive = newinfo.newreporttype;
			this.drilldownitemlive = newinfo.item;
			if(this.goingBack === false) {
				this.drillHistory.push({reporttype: newinfo.newreporttype, item: newinfo.item});
			}
			this.goingBack = false;
		},
		goBack() {
			if(this.drillHistory.length < 1) {
				this.$emit('closedrilldown');
			}
			this.drillHistory.splice(this.drillHistory.length - 1, 1); //remove last item from array
			if(this.drillHistory.length < 1) {
				this.$emit('closedrilldown');
			}
			else {
				var histlength = this.drillHistory.length;
				var prevhistindex = histlength - 1;
				var histitem = this.drillHistory[prevhistindex];
				this.goingBack = true;
				this.switchDrilldown({
					newreporttype: histitem.reporttype,
					item: histitem.item
				});
			}

		},
	},
	watch: {
		// drilldownreporttype() {
		//
		// }
	},
	mounted() {
		this.drilldownreporttypelive = this.drilldownreporttype;
		this.drilldownitemlive = this.drilldownitem;
		this.drillHistory.push({reporttype: this.drilldownreporttype, item: this.drilldownitem});
	}
}
</script>

<style scoped>

</style>