<template>
	<div class="kbtn"
		@click="$emit('clicked')"
		v-bind:class="{'button-is-working':(isworking === true)}"
	>
		<span>{{buttontext}}</span>
		<WorkingButtonInsert v-if="isworking"></WorkingButtonInsert>
	</div>
</template>

<script>
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
export default {
	name: "ActionButton",
	components: {WorkingButtonInsert},
	props: {
		buttontext: String,
		isworking: Boolean,
	},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>