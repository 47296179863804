<template>
	<div>
		<div style="display: flex;">
			<p class="admintitle">Markets</p>
			<span style="margin-left: 30px; height: 28px;" class="mark-complete-btn" @click="createNewMarketPopup">
				Create New
			</span>
			<div style="display: inline-block; margin-left: 30px; margin-top: 2px;">
				<b-form-checkbox v-model="showInactiveMarkets" switch>
					<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Show Inactive</span>
				</b-form-checkbox>
			</div>
		</div>

		<b-input type="text" v-model="searchtext" class="adminsearchbox"></b-input>

		<div class="admin-structure">

			<div class="admin-listview">
				<div v-for="li in shownObjects" :key="li.marketId"
					class="admin-list-row" :class="{highlighted : activeItemId === li.marketId}"
					@click="setActiveItem(li.marketId)"
				>
					<span>{{li.marketName}} ({{li.marketOwner}})</span>
				</div>
			</div>
			<div class="admin-infoview">
				<div v-if="activeItem">
					<div class="admin-info-section" style="display: flex; justify-content: space-between; align-items: center;">
						<span class="admin-item-title">{{activeItem.marketName}}</span>
					</div>
					<div class="admin-tabs-section">
						<span class="admin-tab" @click="activesubtab = 'meta'" :class="{active : activesubtab === 'meta'}">Station Meta</span>
						<span class="admin-tab" @click="activesubtab = 'assignments'" :class="{active : activesubtab === 'assignments'}">Stations Assigned</span>
					</div>
					<div v-if="activesubtab === 'meta'">
						<div class="admin-info-section info-data">
							<p><span class="item-label">Tracka Market ID:</span> {{activeItem.marketId}}</p>
							<p><span class="item-label">Market Owner:</span> {{activeItem.marketOwner}}</p>
							<p><span class="item-label">Create TS UTC:</span> {{activeItem.marketCreateTsUtc}}</p>
							<p><span class="item-label">Update TS UTC:</span> {{activeItem.marketUpdateTsUtc}}</p>
						</div>
						<div class="admin-info-section">
							<p class="input-label">Market Name</p>
							<b-input class="admin-input" type="text" v-model="editingMarket.marketName"></b-input>
							<p class="input-label">Market Owner</p>
							<b-input class="admin-input" type="text" v-model="editingMarket.marketOwner"></b-input>
							<p class="input-label">Market is Active</p>
							<b-form-select v-model="editingMarket.activeFlag" class="admin-input">
								<option value="Y">Yes</option>
								<option value="N">No</option>
							</b-form-select>
							<ActionButton style="margin-top: 20px;" buttontext="Save Metadata" v-on:clicked="saveMarketDetails" :isworking="saveStationDetailsWorking"></ActionButton>
						</div>
					</div>
					<div v-if="activesubtab === 'assignments'" style="padding-top: 10px;">
						<span style="margin-left: 20px; margin-top: 10px; margin-bottom: 10px;" class="kbtn" v-b-modal.modal-station-search>
							<span>Add Station</span>
						</span>
						<Chip v-for="stn in activeMarketAssignedStations" :key="stn.stationId"
							:chipid="stn.stationId" :chipname="stn.stationName"
							v-on:remove="removeStation(stn.stationId)"
						></Chip>
						<div class="admin-info-section">
							<ActionButton buttontext="Save Mappings" v-on:clicked="saveMappings" :isworking="saveStationDetailsWorking"></ActionButton>
						</div>
					</div>

				</div>
			</div>

			<b-modal id="modal-station-search" ref="modal-station-search" title="Station Search" hide-footer>
				<div class="modal-inner" style="padding-top: 10px; padding-bottom: 10px;">
					<Autocompleter :searchlist="stationSearchList"
								idfldname="stationId" textfldname="stationName" v-on:update="addStationToMarketMaplist($event)"></Autocompleter>
				</div>
			</b-modal>

			<b-modal id="modal-create-market" ref="modal-create-market" title="Create Market" hide-footer>
				<div class="modal-inner" style="padding-top: 10px; padding-bottom: 10px;">
					<p class="input-label">Market Name</p>
					<b-input class="admin-input" type="text" v-model="newMarketName"></b-input>
					<p class="input-label">Market Owner</p>
					<b-input class="admin-input" type="text" v-model="newMarketOwner"></b-input>
					<p class="input-label">Market is Active</p>
					<b-form-select v-model="newMarketActive" class="admin-input">
						<option value="Y">Yes</option>
						<option value="N">No</option>
					</b-form-select>
					<ActionButton style="margin-top: 10px;" buttontext="Save New Market" v-on:clicked="saveNewMarketFromPopup" :isworking="saveNewMarketIsWorking"></ActionButton>
				</div>
			</b-modal>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import Chip from "@/components/Chip";
import Autocompleter from "@/components/Autocompleter";
import ActionButton from "@/components/ActionButton";

export default {
	name: "Markets",
	components: {ActionButton, Autocompleter, Chip},
	props: {},
	data: function () {
		return {
			searchtext: '',
			activeItemId: 0,
			activesubtab: 'meta',

			editingMarket: {
				marketId: 0,
				marketName: '',
				marketOwner: '',
				activeFlag: 'Y',
			},

			activeMarketAssignedStations: [],

			newMarketName: '',
			newMarketOwner: 'tracka',
			newMarketActive: 'Y',

			showInactiveMarkets: false,

			saveStationDetailsWorking: false,
			saveNewMarketIsWorking: false,
		}
	},
	computed: {
		stationSearchList() {
			return this.$store.state.stationsAdminList.filter(item => item.activeFlag !== "N");
		},
		listObjects() {
			if(this.showInactiveMarkets) {
				return this.$store.state.marketsAdminList;
			}
			return this.$store.state.marketsAdminList.filter(item => item.activeFlag === "Y");
		},
		shownObjects() {
			if (this.searchtext.length > 0) {
				var src = this.searchtext.toLowerCase();
				return this.listObjects.filter(item =>
					(item.marketName.toLowerCase().includes(src) || item.marketOwner.toLowerCase().includes(src))
				);
			}
			return this.listObjects; //return all if limit not set
		},
		timezones() {
			return this.$store.state.timezonelist;
		},
		activeItem() {
			return this.$store.state.marketsAdminList.find(item => item.marketId === this.activeItemId);
		},
		mappedStationsForActiveMarket() {
			if(this.activeItemId === 0) return [];
			return this.$store.state.stationMarketMappings.filter(item => item.marketId === this.activeItemId);
		},
	},
	methods: {
		createNewMarketPopup() {
			this.newMarketName = '';
			this.newMarketOwner = 'tracka';
			this.newMarketActive = 'Y';
			this.$refs['modal-create-market'].show();
		},
		saveNewMarketFromPopup() {
			if(this.newMarketName.length > 0 && this.newMarketOwner.length > 0) {
				if(this.saveNewMarketIsWorking === false) {
					let form = {
						marketName: this.newMarketName,
						marketOwner: this.newMarketOwner,
						activeFlag: this.newMarketActive,
						marketId: 0
					}
					this.saveNewMarketIsWorking = true;
					let self = this;
					let targ = '/post/admin/save-market.php';
					axios.post(targ, form).then(function (response) {
						let ret = response.data; //response.data is returned info
						console.log(ret);
						self.fetchAdminData()
						self.$refs['modal-create-market'].hide();
						self.saveNewMarketIsWorking = false;
					}).catch(function (error) {
						console.log(error);
						self.saveNewMarketIsWorking = false;
					});
				}
			}
			else {
				alert("Please enter a market name and owner");
			}
		},
		setActiveItem(id) {
			this.activeItemId = id;
			this.editingMarket.marketId = id;
			this.editingMarket.marketName = this.activeItem.marketName;
			this.editingMarket.marketOwner = this.activeItem.marketOwner;
			this.editingMarket.activeFlag = this.activeItem.activeFlag;
			this.activeMarketAssignedStations = this.mappedStationsForActiveMarket;
		},
		saveMarketDetails() {
			if(this.saveStationDetailsWorking === false) {
				let form = {
					marketName: this.editingMarket.marketName,
					marketOwner: this.editingMarket.marketOwner,
					activeFlag: this.editingMarket.activeFlag,
					marketId: this.editingMarket.marketId
				}
				this.saveStationDetailsWorking = true
				let self = this
				let targ = '/post/admin/save-market.php'
				axios.post(targ, form).then(function (response) {
					var ret = response.data; //response.data is returned info
					console.log(ret);
					self.fetchAdminData()
					self.saveStationDetailsWorking = false;
				}).catch(function (error) {
					console.log(error);
					self.saveStationDetailsWorking = false;
				});
			}
		},
		removeStation(removeStnId) {
			this.activeMarketAssignedStations = this.activeMarketAssignedStations.filter(item => item.stationId !== removeStnId);
		},
		saveMappings() {
			if(this.saveStationDetailsWorking === false) {
				let stnids = [];
				for (let i = 0; i < this.activeMarketAssignedStations.length; i++) {
					stnids.push(this.activeMarketAssignedStations[i].stationId); //stationId for stations
				}
				let self = this;
				let form = {
					marketId: this.editingMarket.marketId,
					stationIdList: stnids
				};
				this.saveStationDetailsWorking = true;
				let targ = '/post/admin/save-market-mapping.php'
				axios.post(targ, form).then(function (response) {
					let ret = response.data; //response.data is returned info
					console.log(ret);
					self.fetchAdminData()
					self.saveStationDetailsWorking = false;
				}).catch(function (error) {
					console.log(error);
					self.saveStationDetailsWorking = false;
				});
			}
		},
		addStationToMarketMaplist(stnob) {
			if(stnob.stationId > 0) {
				var alreadyinarray = this.activeMarketAssignedStations.find(item => item.stationId === stnob.stationId);
				if(!alreadyinarray) {
					var stnToAdd = this.$store.state.stationsAdminList.find(item => item.stationId === stnob.stationId);
					this.activeMarketAssignedStations.push(stnToAdd);
					this.$refs['modal-station-search'].hide();
				}
			}
		},
	},
	watch: {
		listObjects() {
			if(this.activeItemId === 0) {
				this.setActiveItem(this.listObjects[0].marketId);
			}
		},
		mappedStationsForActiveMarket() {
			this.activeMarketAssignedStations = this.mappedStationsForActiveMarket;
		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>