<template>
	<div>
		<div class="report-top">
			<div class="headingHold">
				<h2 id="resultsHeading">
					{{heading}}
				</h2>
			</div>

			<div style="width: 300px; display: flex; align-items: center">
				<b-form-input
						id="filter-input"
						v-model="filter"
						type="search"
						placeholder="Type to Search"
						style="font-size: 13px;"
				></b-form-input>
				<div v-if="returndata.length > 0" class="icon-excel-hold" @click="toExcel" title="Download report to Excel file"><i class="fa fa-file-excel-o"></i></div>
			</div>
		</div>

		<div class="content-container report-section">
			<b-table :fields="fields" :items="returndata" :tbody-tr-class="'trow'" :filter="filter">
				<template #cell(stationId)="data">
					<span>{{getStationNameFromId(data.value)}}</span>
					<div class="icon-holder">
						<i class="fa fa-clock cursorPointer rowIcon" @click="drillDown('SongSpins', data.item);" :title="'Click to view other '+getStationNameFromId(data.item.stationId)+' play times'"></i>
					</div>
				</template>
			</b-table>
		</div>
		<BugReporter v-if="$store.state.bugreportshown"></BugReporter>
	</div>
</template>

<script>
import axios from "axios";
import BugReporter from "@/components/BugReporter";

export default {
	name: "DrilldownSongSpinsMarketSummary",
	components: {
		BugReporter
	},
	props: {
		item: Object,
		selections: Object,
	},
	data: function () {
		return {
			reportType: 'SongSpinsMarketSummary', //for API URL
			fetching: false,
			reportTitle: 'Song Spins Market Summary',
			heading: 'Market Summary',
			filter: '',
			fields: [
				{key: 'stationId', label: 'Station', sortable: true},
				{key: 'rotation', label: 'Avg Rotation (Hrs)', sortable: true},
				{key: 'plays', label: 'Plays', sortable: true},
			],
			returndata: [],
		}
	},
	computed: {

	},
	methods: {
		drillDown(newreporttype, item) {
			this.$emit('switchdrilldown', {
				newreporttype: newreporttype,
				item: item
			});
		},
		getReport() {
			if(this.fetching === false) {
				let self = this;
				let parstr = "marketId=" + this.selections.marketid+"&trackaSongId=" + this.item.songItem.trackaSongId + "&daypart=ALL&dateRange=past30";
				let targ = "/api-web/song-spins-drilldown.php?" + parstr;
				this.$store.commit('setLastQueryUrl', targ);
				this.fetching = true;
				document.title = "Fetching data...";
				axios.get(targ).then(function(response) {
					var ret = response.data; //response.data is returned info
					document.title = 'Tracka - '+self.reportTitle;
					self.returndata = ret;
					self.fetching = false;
					self.heading = "Market Summary: "+self.item.songItem.artist+ " - "+self.item.songItem.title;
					self.sendLoggingInfo(self.reportType, self.selections);
				}).catch(function(error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
		toExcel() {
			var form = {};
			form.rows = this.prepareExcelTableRows(this.fields, this.returndata);
			form.layout = this.reportType;
			form.filename = this.getMarketFromId(this.selections.marketid);
			var targ = '/post/get-excel.php';
			axios.post(targ, form).then(function (response) {
				window.location = response.data;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		item() {
			this.getReport();
		},
		fetching() {
			this.$store.commit('showLoader', this.fetching);
		}
	},
	mounted() {
		this.getReport();
	}
}
</script>

<style scoped>

</style>