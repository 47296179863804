<template>
	<div class="">
		<div class="content-container report-section">
			<div class="report-top">
				<div class="headingHold">
					<h2 id="resultsHeading">
						{{heading}}
					</h2>
					<p id="resultsSubheading">{{subheading}}</p>
				</div>
			</div>

			<div style="display:flex; justify-content: space-between;">
				<div class="selection-parent">
					<p>Start Time</p>
					<select class="selection-audio" v-model="selectedStartTime">
						<option v-for="p in starthourtimes" :key="p" :value="p">{{p}}</option>
					</select>
				</div>
				<div class="selection-parent">
					<p>End Time</p>
					<select class="selection-audio" v-model="selectedEndTime">
						<option v-for="p in endhourtimes" :key="p" :value="p">{{p}}</option>
					</select>
				</div>
			</div>
			<div v-if="isworking !== true && okToStartDownload" style="margin-top: 20px;">
				<button type="button" class="audiobutton" @click="getAndDownloadAudio">Download</button>
			</div>
			<div v-if="isworking">
				<br/><br/>
				<p>Loading...please wait...this may take a little while for large audio files</p>
			</div>

		</div>
	</div>
</template>

<script>
import {DateTime} from 'luxon';
export default {
	name: "AudioDownload",
	components: {

	},
	props: {
		selections: Object,
	},
	data: function () {
		return {
			showSelects: {
				market: true,
				brand: true,
				period: false,
				property: false,
				daypart: false,
				logdate: true,
				startdate: false,
				enddate: false,
			},
			reportType: 'AudioDownload', //for API URL
			fetching: false,
			started: false,
			reportTitle: "Audio download",

			heading: 'Audio download',
			subheading: 'Select your start and end times, then click Download.  Maximum 3 hour block.',

			isworking: false,

			starthourtimes: [],
			endhourtimes: [],
			selectedStartTime: '00:00:00',
			selectedEndTime: '00:15:00',
		}
	},
	computed: {
		okToStartDownload() {
			if(this.selections.stationid > 0 && this.selections.marketid > 0 && this.selections.logdate && this.selections.logdate.length > 0) {
				return true;
			}
			return false;
		},
	},
	methods: {
		getAndDownloadAudio() {
			let stnob = this.$store.state.stationsAdminList.find(item => item.stationId === this.selections.stationid);
			this.isworking = true;
			if(stnob && this.selections.logdate.length > 0) {
				let sd = DateTime.fromISO(this.selections.logdate+"T"+this.selectedStartTime, {zone: stnob.stationTz});
				let sdutc = sd.setZone('UTC', {keepLocalTime: false});
				let ed = DateTime.fromISO(this.selections.logdate+"T"+this.selectedEndTime, {zone: stnob.stationTz});
				let edutc = ed.setZone('UTC', {keepLocalTime: false});
				if(sd > ed) {
					alert("Please ensure start time is earlier to end time");
					return false;
				}
				let queryStr = this.$store.state.apiMichaelUrl+"Recording/Download";
				let sendstring = "station=" + this.getStationNameFromId(this.selections.stationid) + "&market=" + this.getMarketFromId(this.selections.marketid);
				sendstring += "&from=" + sdutc.toISO() + "&to=" + edutc.toISO();
				window.location = queryStr+"?"+sendstring; //this forces the download
				this.$store.commit('setLastQueryUrl', queryStr+"?"+sendstring);
				this.sendLoggingInfo('AudioDownload', this.selections);
				setTimeout(function() {
					this.isworking = false;
				}, 8000);
			}
			else {
				alert("Please make a selection from each dropdown list");
			}
		},
		createTimepickers() {
			var starts = [];
			var ends = [];
			var mins = ["00","15","30","45"];
			for(var h=0; h<=25; h++) {
				for(var j=0; j<mins.length; j++) {
					if(h<24 || (h === 24 && j === 0)) {
						var min = mins[j];
						var hour = h;
						var sec = ":00";
						if(h < 10) {
							hour = "0" + h;
						}
						var mytime = hour + ":" + min + sec;
						if(h < 24) {
							starts.push(mytime);
						}
						if(h > 0 || j > 0) {
							if(h === 24) { //replace 24:00 with 23:59:59
								hour = 23;
								min = 59;
								sec = ":59";
							}
							mytime = hour + ":" + min + sec;
							ends.push(mytime);
						}
					}
				}
			}
			this.starthourtimes = starts;
			this.endhourtimes = ends;
		},
	},
	watch: {
		fetching() {
			this.$store.commit('showLoader', this.fetching);
		},
		selectedStartTime() {
			var ind = this.starthourtimes.indexOf(this.selectedStartTime);
			this.selectedEndTime = this.endhourtimes[ind];
		},
	},
	mounted() {
		this.checkFetchUserAppData(); //triggers fetch items from kev db for usage in front end for user
		this.$store.commit('setMusicDropdownItems', this.showSelects);
		document.title = 'Tracka - '+this.reportTitle;
		this.createTimepickers();
	}
}
</script>

<style scoped>
.selection-audio {
	height: 40px;
	width: 100%;
	background: #fff;
	font-size: 13px;
	margin-top: 10px;
	font-family: 'Open Sans', sans-serif;;
	border: 1px solid #CCC;
}
.selection-parent {
	width: 49%;
}
.audiobutton {
	height: 40px;
	background: #F38230;
	color: #fff;
	text-transform: uppercase;
	width: 140px;
	border: none;
	border-radius: 5px;
	font-family: 'Quicksand', sans-serif;
	font-size: 14px;
	font-weight: 500;
	padding: 10px;
	letter-spacing: 0.03em;
	cursor: pointer;
}
.audiobutton:hover {
	background: #e96c00;
}
</style>