<template>
	<div>
		<div style="display: flex;">
			<p class="admintitle">Reports</p>
			<span style="margin-left: 30px; height: 28px;" class="mark-complete-btn" @click="createNewPopup">
				Create New
			</span>
			<div style="display: inline-block; margin-left: 30px; margin-top: 2px;">
				<b-form-checkbox v-model="showInactive" switch>
					<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Show Inactive</span>
				</b-form-checkbox>
			</div>
		</div>

		<b-input type="text" v-model="searchtext" class="adminsearchbox"></b-input>

		<div class="admin-structure">

			<div class="admin-listview">
				<div v-for="li in shownObjects" :key="li.id"
					class="admin-list-row" :class="{highlighted : activeItemId === li.id}"
					@click="setActiveItem(li.id)"
				>
					<span>{{li.title}}</span>
				</div>
			</div>
			<div class="admin-infoview">
				<div v-if="activeItem">
					<div class="admin-info-section" style="display: flex; justify-content: space-between; align-items: center;">
						<span class="admin-item-title">{{activeItem.title}}</span>
					</div>
					<div class="admin-tabs-section">
						<span class="admin-tab" @click="activesubtab = 'meta'" :class="{active : activesubtab === 'meta'}">Report Meta</span>
					</div>
					<div v-if="activesubtab === 'meta'">
						<div class="admin-info-section info-data">
							<p><span class="item-label">Report ID:</span> {{activeItem.id}}</p>
						</div>
						<div class="admin-info-section">
							<p class="input-label">Report Title</p>
							<b-input class="admin-input" type="text" v-model="editingReport.title"></b-input>
							<p class="input-label">Lookup String (for API)</p>
							<b-input class="admin-input" type="text" v-model="editingReport.lookupval"></b-input>
							<p class="input-label">FontAwesome Code (for tile)</p>
							<b-input class="admin-input" type="text" v-model="editingReport.facode"></b-input>
							<b-form-checkbox v-model="editingReport.devonly" switch>
								<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Dev Only</span>
							</b-form-checkbox>
							<b-form-checkbox v-model="editingReport.iscustom" switch>
								<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Is Custom Report</span>
							</b-form-checkbox>
							<b-form-checkbox v-model="editingReport.marknew" switch>
								<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Mark as 'new'</span>
							</b-form-checkbox>
							<b-form-checkbox v-model="editingReport.markimproved" switch>
								<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Mark as 'updated'</span>
							</b-form-checkbox>
							<b-form-checkbox v-model="editingReport.deleted" switch>
								<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Inactive</span>
							</b-form-checkbox>
							<ActionButton style="margin-top: 20px;" buttontext="Save Metadata" v-on:clicked="saveDetails" :isworking="saveDetailsWorking"></ActionButton>
						</div>
					</div>

				</div>
			</div>

			<b-modal id="modal-create-new" ref="modal-create-new" title="Create New Report Record" hide-footer>
				<div class="modal-inner" style="padding-top: 10px; padding-bottom: 10px;">
					<p class="input-label">Report Title</p>
					<b-input class="admin-input" type="text" v-model="newReport.title"></b-input>
					<p class="input-label">Lookup String (for API)</p>
					<b-input class="admin-input" type="text" v-model="newReport.lookupval"></b-input>
					<p class="input-label">FontAwesome Code (for tile)</p>
					<b-input class="admin-input" type="text" v-model="newReport.facode"></b-input>
					<b-form-checkbox v-model="newReport.devonly" switch>
						<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Dev Only</span>
					</b-form-checkbox>
					<b-form-checkbox v-model="newReport.iscustom" switch>
						<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Is Custom Report</span>
					</b-form-checkbox>
					<b-form-checkbox v-model="newReport.marknew" switch>
						<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Mark as 'new'</span>
					</b-form-checkbox>
					<b-form-checkbox v-model="newReport.markimproved" switch>
						<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Mark as 'updated'</span>
					</b-form-checkbox>
					<b-form-checkbox v-model="newReport.deleted" switch>
						<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Inactive</span>
					</b-form-checkbox>
					<ActionButton style="margin-top: 10px;" buttontext="Save New Report" v-on:clicked="saveNewFromPopup" :isworking="saveNewIsWorking"></ActionButton>
				</div>
			</b-modal>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import ActionButton from "@/components/ActionButton";

export default {
	name: "Reports",
	components: {ActionButton},
	props: {},
	data: function () {
		return {
			searchtext: '',
			activeItemId: 0,
			activesubtab: 'meta',

			editingReport: {
				id: 0,
				title: '',
				lookupval: '',
				facode: '',
				deleted: false,
				devonly: null,
				iscustom: null,
				marknew: null,
				markimproved: null,
			},

			newReport: {
				id: 0,
				title: '',
				lookupval: '',
				facode: '',
				deleted: false,
				devonly: null,
				iscustom: null,
				marknew: null,
				markimproved: null,
			},

			showInactive: false,

			saveDetailsWorking: false,
			saveNewIsWorking: false,
		}
	},
	computed: {
		listObjects() {
			if(this.showInactive === false) {
				return this.$store.state.reportsAdminList.filter(item => item.deleted === false);
			}
			return this.$store.state.reportsAdminList;
		},
		shownObjects() {
			if (this.searchtext.length > 0) {
				var src = this.searchtext.toLowerCase();
				return this.listObjects.filter(item =>
					(item.title.toLowerCase().includes(src) || item.lookupval.toLowerCase().includes(src))
				);
			}
			return this.listObjects; //return all if limit not set
		},
		activeItem() {
			return this.$store.state.reportsAdminList.find(item => item.id === this.activeItemId);
		},
	},
	methods: {
		createNewPopup() {
			this.newReport = {
				id: 0,
					title: '',
					lookupval: '',
					facode: '',
					deleted: false,
					devonly: null,
					iscustom: null,
					marknew: null,
					markimproved: null,
			};
			this.$refs['modal-create-new'].show();
		},
		saveNewFromPopup() {
			if(this.newReport.lookupval.length > 0 && this.newReport.title.length > 0) {
				if(this.saveNewIsWorking === false) {
					var form = {};
					form.editingReport = this.newReport;
					this.saveNewIsWorking = true;
					var self = this;
					var targ = '/post/admin/save-report-details.php';
					axios.post(targ, form).then(function (response) {
						var ret = response.data; //response.data is returned info
						self.$store.commit('setFromAdminReturns', ret);
						self.$refs['modal-create-new'].hide();
						self.saveNewIsWorking = false;
					}).catch(function (error) {
						console.log(error);
						self.saveNewIsWorking = false;
					});
				}
			}
			else {
				alert("Please enter a market name and owner");
			}
		},
		setActiveItem(id) {
			this.activeItemId = id;
			this.editingReport.id = id;
			this.editingReport.title = this.activeItem.title;
			this.editingReport.lookupval = this.activeItem.lookupval;
			this.editingReport.facode = this.activeItem.facode;
			this.editingReport.devonly = this.activeItem.devonly;
			this.editingReport.iscustom = this.activeItem.iscustom;
			this.editingReport.marknew = this.activeItem.marknew;
			this.editingReport.markimproved = this.activeItem.markimproved;
			this.editingReport.deleted = this.activeItem.deleted;
		},
		saveDetails() {
			if(this.saveDetailsWorking === false) {
				var form = {};
				form.editingReport = this.editingReport;
				this.saveDetailsWorking = true;
				var self = this;
				var targ = '/post/admin/save-report-details.php';
				axios.post(targ, form).then(function (response) {
					var ret = response.data; //response.data is returned info
					self.$store.commit('setFromAdminReturns', ret);
					self.saveDetailsWorking = false;
				}).catch(function (error) {
					console.log(error);
					self.saveDetailsWorking = false;
				});
			}
		},
	},
	watch: {
		listObjects() {
			if(this.activeItemId === 0) {
				this.setActiveItem(this.listObjects[0].id);
			}
		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>