<template>
	<div class="main-section">
		<NavMenu></NavMenu>
		<MusicSelects v-on:getreport="getReport($event)" v-on:selectionchange="selections = $event"></MusicSelects>

		<BrandPlaylistLog v-if="selections.reporttype === 'StationPlaylistLog'" ref="StationPlaylistLog" :selections="selections"></BrandPlaylistLog>
		<StationApraReport v-if="selections.reporttype === 'StationApraReport'" ref="StationApraReport" :selections="selections"></StationApraReport>
		<StationPpcaReport v-if="selections.reporttype === 'StationPpcaReport'" ref="StationPpcaReport" :selections="selections"></StationPpcaReport>
		<StationPpcaReport v-if="selections.reporttype === 'StationPpcaReport18hr'" ref="StationPpcaReport18hr" :selections="selections"></StationPpcaReport>
		<TopTracks v-if="selections.reporttype === 'TopTracks'" ref="TopTracks" :selections="selections"></TopTracks>
		<HotTracks v-if="selections.reporttype === 'HotTracks'" ref="HotTracks" :selections="selections"></HotTracks>
		<NewTracks v-if="selections.reporttype === 'NewTracks'" ref="NewTracks" :selections="selections"></NewTracks>
		<TopMarketTracks v-if="selections.reporttype === 'TopMarketTracks'" ref="TopMarketTracks" :selections="selections"></TopMarketTracks>
		<MarketSideBySideLog v-if="selections.reporttype === 'MarketSideBySideLog'" ref="MarketSideBySideLog" :selections="selections"></MarketSideBySideLog>
		<AudioDownload v-if="selections.reporttype === 'AudioDownload'" ref="AudioDownload" :selections="selections"></AudioDownload>
		<MarketDNAComparison v-if="selections.reporttype === 'MarketDNAComparison'" ref="MarketDNAComparison" :selections="selections"></MarketDNAComparison>
		<StationFingerprint v-if="selections.reporttype === 'StationFingerprint'" ref="StationFingerprint" :selections="selections"></StationFingerprint>

	</div>
</template>

<script>
import NavMenu from "@/components/NavMenu";
import MusicSelects from "@/components/MusicSelects";
import BrandPlaylistLog from "@/components/music-report-views/BrandPlaylistLog";
import StationApraReport from "@/components/music-report-views/StationApraReport";
import StationPpcaReport from "@/components/music-report-views/StationPpcaReport";
import TopTracks from "@/components/music-report-views/TopTracks";
import HotTracks from "@/components/music-report-views/HotTracks";
import NewTracks from "@/components/music-report-views/NewTracks";
import TopMarketTracks from "@/components/music-report-views/TopMarketTracks";
import MarketSideBySideLog from "@/components/music-report-views/MarketSideBySideLog";
import AudioDownload from "@/components/music-report-views/AudioDownload";
import MarketDNAComparison from "@/components/music-report-views/MarketDNAComparison";
import StationFingerprint from "@/components/music-report-views/StationFingerprint";
export default {
	name: "MusicReport",
	components: {
		StationFingerprint,
		MarketDNAComparison,
		AudioDownload,
		MarketSideBySideLog,
		TopMarketTracks,
		NewTracks,
		HotTracks,
		TopTracks,
		StationPpcaReport,
		StationApraReport,
		BrandPlaylistLog,
		MusicSelects,
		NavMenu
	},
	props: {},
	data: function () {
		return {
			selections: {},
			triggerfetch: false,
		}
	},
	computed: {

	},
	methods: {
		getReport() {
			this.$refs[this.selections.reporttype].getReport();
		},

	},
	watch: {

	},
	mounted() {
		this.checkFetchUserAppData(); //triggers fetch items from kev db for usage in front end for user
		document.title = 'Tracka Music Reports';
	}
}
</script>

<style scoped>


</style>