<template>
	<div>
		<div style="display: flex;">
			<p class="admintitle">Logging Reports</p>
		</div>

		<div class="select-container-section">
			<div class="form">
				<div class="select-holder">
					<span class="select-label" style="display: block; margin-bottom: 10px;">Start Date</span>
					<b-form-datepicker class="datechoose" v-model="dateStart" :date-format-options="dateFormatOptions" locale="en" placeholder="Choose"
					></b-form-datepicker>
				</div>

				<div class="select-holder">
					<span class="select-label" style="display: block; margin-bottom: 10px;">End Date</span>
					<b-form-datepicker class="datechoose" v-model="dateEnd" :date-format-options="dateFormatOptions" locale="en" placeholder="Choose"
					></b-form-datepicker>
				</div>

				<div class="select-holder">
					<span class="select-label">User or Group</span>
					<select id="userselect" v-model="userchosen">
						<option value="">All</option>
						<option v-for="u in $store.state.usersAdminList" :key="u.id" :value="u.id">{{u.name}}</option>
					</select>
				</div>

				<button type="button" @click="getResults" id="processbtn">Submit</button>

			</div>
		</div>

		<div id="results-target">
			<table id="log_table" >
				<thead>
				<th>User</th>
				<th>Market Selection (Instances)</th>
				<th>Report Type (Instances)</th>
				</thead>
				<tbody id="log_data">
					<tr v-for="u in userResults" :key="u.uid">
						<td>{{getUserNameFromId(u.uid)}}</td>
						<td class="data-markets">
							<p v-for="m in u.markets" :key="m.mktid">{{getMarketFromId(m.mktid)}}: {{m.count}}</p>
						</td>
						<td class="data-reports">
							<p v-for="m in u.reports" :key="m.rptid">{{m.rptid}}: {{m.count}}</p>
						</td>
					</tr>

				</tbody>
			</table>
		</div>

	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "LogReportView",
	components: {},
	props: {},
	data: function () {
		return {
			userchosen: '',
			reportType: 'user-totals',
			dateStart: null,
			dateEnd: null,

			dateFormatOptions: { year: 'numeric', month: 'short', day: '2-digit' },
			fetchDbWorking: false,

			userResults: [],
		}
	},
	computed: {},
	methods: {
		getResults() {
			if(this.fetchDbWorking === false) {
				this.fetchDbWorking = true;
				var self = this;
				var formdata = {
					userfilter: this.userchosen,
					start: this.dateStart,
					end: this.dateEnd
				};
				var targ = "/post/admin/get-logging-usage.php";
				axios.post(targ, JSON.stringify(formdata)).then(function (response) {
					self.userResults = response.data['master'];
					self.fetchDbWorking = false;
				}).catch(function (error) {
					console.log(error);
					self.fetchDbWorking = false;
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
div.form p { display: inline-block; }
div.form { text-align: center; }
#userselect, #userselect option{ text-transform: capitalize;}
div#results-target{ padding: 50px; }
table#log_table { width: 100%; border-collapse: collapse; }
table#log_table th, table#log_table td{ border: 1px solid #f3f0f0; padding: 10px; }
table#log_table p{ margin-bottom:0; margin-top: 0 !important; font-size: 13px; font-weight: 900; }
th {
	text-align: left;
}

.select-holder {
	width: 15%;
	display: inline-block;
	padding: 0 5px;
}
.select-label {
	width: 100%;
	color: #36373a;
	font-family: 'Quicksand', sans-serif;
	font-weight: 500;
	font-size: 15px;
}
.report-type-select-holder {
	width: 20%;
}
#userselect, .datechoose {
	height: 40px;
	width: 100%;
	border: none;
	background: #fff;
	font-size: 13px;
	margin-top: 10px;
	font-family: 'Open Sans', sans-serif;;
}
.datechoose {

}
#processbtn {
	height: 40px;
	margin-left: 5px;
	background: #F38230;
	color: #fff;
	text-transform: uppercase;
	width: 140px;
	border: none;
	border-radius: 5px;
	font-family: 'Quicksand', sans-serif;
	font-size: 14px;
	font-weight: 500;
	padding: 10px;
	letter-spacing: 0.03em;
}
#processbtn:hover {
	background: #e96c00;
}

</style>