<template>
	<div id="audioHold" class="hidden">
		<audio id="audioPlayer" src=""></audio>
		<span id="audioHoldMsg">
			<span v-if="isPlaying" @click="stopAudio" v-html="audiomsg"></span>
			<span v-if="isPlaying === false" @click="playAudio(null)" v-html="audiomsg"></span>
		</span>
	</div>
</template>

<script>
export default {
	name: "AudioPlayer",
	components: {},
	props: {
		audioitem: Object,
		selections: Object,
	},
	data: function () {
		return {
			isLoading: null,
			audiomsg: "<i class='fa fa-stop'></i> Playing",
			isPlaying: false,
		}
	},
	computed: {},
	methods: {
		getAndPlayAudio() {
			let item = this.audioitem;
			if(this.isLoading) {
				this.audiomsg = "Audio is already loading...please wait";
			}
			else {
				let audhold = document.getElementById('audioHold');
				audhold.classList.remove('hidden');
				this.audiomsg = "Loading...please wait";
				let audioplayer = document.getElementById("audioPlayer");
				audioplayer.pause();
				this.isLoading = true;
				let endtime = this.getEOHTime(item.songPlayedTsUtc);
				let mktname = this.getMarketFromId(this.selections.marketid)
				// if(item.marketName && item.marketName !== "") mktname = item.marketName;
				// else if(item.stationMarket && item.stationMarket !== "") mktname = item.stationMarket;
				let queryStr = this.$store.state.apiMichaelUrl+"Recording/Stream";
				let starttimeUtc = item.songPlayedTsUtc.replace(' ', 'T')+"Z";
				let sendstring = "station=" + this.getStationNameFromId(item.stationId) + "&market=" + mktname + "&from=" + starttimeUtc + "&to=" + endtime;
				this.playAudio(queryStr+"?"+sendstring);
			}
		},
		playAudio(data) {
			var audioplayer = document.getElementById("audioPlayer");
			if(data) {
				audioplayer.src = data;
			}
			audioplayer.play();
			setTimeout(function() {
				this.checkIfAudioPlaying();
			}.bind(this), 3000);
		},
		checkIfAudioPlaying() {
			var audioplayer = document.getElementById("audioPlayer");
			if(audioplayer.duration > 0 && !audioplayer.paused) {
				this.audioPlaying();
				return false; //it's playing - cancel the check loop
			}
			else {
				setTimeout(function() {
					this.checkIfAudioPlaying();
				}.bind(this), 2500);
			}
		},
		audioPlaying() {
			this.audiomsg = "<i class='fa fa-stop'></i> Playing";
			this.isPlaying = true;
			this.isLoading = false;
		},
		stopAudio() {
			var audioplayer = document.getElementById("audioPlayer");
			audioplayer.pause();
			this.isPlaying = false;
			this.audiomsg = "<i class='fa fa-play'></i> Paused";
		},
	},
	watch: {
		audioitem() {
			if(this.audioitem) {
				this.getAndPlayAudio();
			}
		},
	},
	mounted() {

	}
}
</script>

<style scoped>
#audioHold {
	display: inline-block;
	align-items: center;
	padding: 10px 20px;
	background-color: #F38230;
	color: #FFF;
	margin-top: 10px;
	margin-bottom: -10px;
	border-radius: 5px;
}
#audioHold.hidden {
	display: none;
}
#audioHoldMsg {
	font-size: 12px;
}
</style>