<template>
	<a class="aNoDec" href="javascript:void(0);" @click="getAndDownloadAudio" title="Download hour audio">
		<span v-if="status !== 'working'">
			<span v-if="status === 'ready'">Download Audio </span>
			<i class="fa fa-download cursorPointer rowIcon"></i>
		</span>
		<span v-if="status === 'working'" class="rowIcon">Working...please wait</span>
	</a>
</template>

<script>
export default {
	name: "AudioDownloadFromStationLog",
	components: {},
	props: {
		item: Object,
		selections: Object,
	},
	data: function () {
		return {
			status: 'normal',
		}
	},
	computed: {},
	methods: {
		getAndDownloadAudio() {
			let item = this.item
			this.status = 'working'
			let queryStr = this.$store.state.apiMichaelUrl+"Recording/Download";
			let sendstring = "station=" + this.getStationNameFromId(item.stationId) + "&market=" + this.getMarketFromId(this.selections.marketid) + "&from=" + item.songPlayedTsUtc + "&to=" + this.getEOHTime(item.songPlayedTsUtc);
			window.location = queryStr+"?"+sendstring; //this forces the download
			this.$store.commit('setLastQueryUrl', queryStr+"?"+sendstring);
			this.sendLoggingInfo('AudioDownloadFromLog', this.selections);
			setTimeout(function() {
				this.status = 'ready';
			}, 8000);
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>