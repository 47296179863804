<template>
	<div>
		<NavMenuAdmin></NavMenuAdmin>

		<div class="admin-holder">
			<Stations v-if="activeAdminSection === 'stations'"></Stations>
			<Markets v-if="activeAdminSection === 'markets'"></Markets>
			<Reports v-if="activeAdminSection === 'reports'"></Reports>
			<Companies v-if="activeAdminSection === 'companies'"></Companies>
			<Users v-if="activeAdminSection === 'users'"></Users>
			<SongBugs v-if="activeAdminSection === 'songbugs'"></SongBugs>
			<LogReportView v-if="activeAdminSection === 'logging'"></LogReportView>

		</div>

	</div>
</template>

<script>
import NavMenuAdmin from "@/components/NavMenuAdmin";
import Stations from "@/components/admin/Stations";
// import axios from "axios";
import Markets from "@/components/admin/Markets";
import Reports from "@/components/admin/Reports";
import Companies from "@/components/admin/Companies";
import Users from "@/components/admin/Users";
import SongBugs from "@/components/admin/SongBugs";
import LogReportView from "@/components/admin/LogReportView";
export default {
	name: "Admin",
	components: {
		LogReportView,
		SongBugs,
		Users,
		Companies,
		Reports,
		Markets,
		Stations,
		NavMenuAdmin
	},
	props: {},
	data: function () {
		return {}
	},
	computed: {
		activeAdminSection() {
			return this.$store.state.activeSectionAdmin;
		}
	},
	methods: {

	},
	watch: {},
	mounted() {
		if(this.$store.state.user.isAdmin !== 1 && this.$store.state.user.isSuperUser !== 1) {
			this.$router.push('/music');
			return false;
		}
		this.fetchAdminData(); //from al. reports/companies
	}
}
</script>

<style scoped>
.admin-holder {
	width: calc(100% - 20px);
	margin: 80px auto 0;
	height: calc(100vh - 90px);
}
</style>