<template>
	<div class="nav-holder">
		<div class="top-row">
			<div class="content-container">
				<div class="left-top-menu">
					<div class="nav-logo-holder">
						<router-link to="/admin">
							<img src="@/assets/logo.png" alt="Logo" class="logo-img" />
						</router-link>
					</div>
				</div>
				<div class="right-top-menu">
					<span class="nav-item" @click="switchAdminTab('stations')">Stations</span>
					<span class="nav-item" @click="switchAdminTab('markets')">Markets</span>
					<span class="nav-item" @click="switchAdminTab('reports')">Reports</span>
					<span class="nav-item" @click="switchAdminTab('companies')">Companies</span>
					<span class="nav-item" @click="switchAdminTab('users')">Users</span>
					<span class="nav-item" @click="switchAdminTab('songbugs')">Song Bugs</span>
					<span class="nav-item" @click="switchAdminTab('logging')">Logging</span>
					<router-link to="/music">
						<span class="nav-item">Music Main</span>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'NavMenuAdmin',
	components: {
	},
	data() {
		return {

		}
	},
	props: {
		obj: Object,
	},
	computed: {
		// routepath() {
		// 	return this.$router.currentRoute.path;
		// }
		activeAdminSection() {
			return this.$store.state.activeSectionAdmin;
		}
	},
	methods: {

	},
}
</script>

<style scoped>
.nav-holder {
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	z-index: 9;
}
.top-row {
	height: 70px;
	width: 100%;
	background-color: #00042b;
	position: relative;
}
.top-row .content-container {
	display: flex;
	justify-content: space-between;
	height: 100%;
}
.left-top-menu {
	display: flex;
	align-content: center;
}
.left-top-menu a {
	display: flex;
	align-items: center;
}
.right-top-menu {
	display: flex;
	align-items: center;
}
.right-top-menu .nav-item {
	text-transform: uppercase;
	color: #cfd3da;
	font-size: 13px;
	font-weight: 500;
	cursor: pointer;
	font-family: 'Quicksand';
}
.right-top-menu .nav-item:hover {
	color: #fff;
}
.nav-logo-holder {
	display: flex;
	align-items: center;
}
.logo-img {
	height: 36px;
	margin-top: -5px;
}
.nav-item {
	padding: 5px 10px;
}

</style>
