<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata.length > 0" width="99%" height="100%" type="bar" :options="chartoptions" :series="chartdata"></apexchart>
	</div>
</template>

<script>
export default {
	name: "SharedSongs",
	components: {},
	props: {
		chartdata: Array,
		chartcategories: Array,
	},
	data: function () {
		return {
			chartoptions: {
				type: 'bar',
				colors: this.$store.state.chartColours,
				chart: {
					toolbar: {
						show: false,
					},
					events: {
						click: function(event, chartContext, config) {
							// console.log(config);
							// console.log(config.config.series[config.seriesIndex]);
							// console.log(config.config.series[config.seriesIndex].name);
							// console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex]);
							// console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex]['x']);
							// let compstnname = config.config.series[config.seriesIndex].data[config.dataPointIndex]['x'];
							// let compstnname = config.config.xaxis.categories[config.dataPointIndex]['x'];
							// this.$emit('songlistdrill', compstnname);
							this.$emit('songlistdrill', config.config.series[config.seriesIndex].data[config.dataPointIndex]['compstid']);
						}.bind(this),
					}
				},
				plotOptions: {
					bar: {
						columnWidth: '80%',
						distributed: true,
					},
				},
				xaxis: {
					categories: [

					],
					labels: {
						show: true,
						rotate: -45,
						rotateAlways: true,
					},
				},
				labels: {

				},
				legend: {
					show: false,
				},
				tooltip: {
					x: {
						formatter: (seriesName) => seriesName + " (click to view shared list)",
					},
					y: {
						title: {
							formatter: (seriesName) => seriesName,
						}
					},
					z: {

					},
				},
			},

		}
	},
	computed: {

	},
	methods: {
		updateCategories() { //need to do this manually as data is nested
			var oldxaxis = this.chartoptions.xaxis;
			oldxaxis.categories = this.chartcategories;
			this.chartoptions = {...this.chartoptions, ...{
					xaxis: oldxaxis
				}};
		},
	},
	watch: {
		chartcategories() {
			this.updateCategories();
		},
	},
	mounted() {
		this.updateCategories();
	}
}
</script>

<style scoped>

</style>