<template>
	<div>
		<div class="admin-info-section">
			<div v-if="fetchDbWorking">Loading from database, please wait...</div>
			<div v-else>
				<div style="display: flex; align-items: center;">
					<span class="item-label" style="margin-bottom: 5px;">Artist</span>
					<b-input class="admin-input" type="text" v-model="editingSong.artist"></b-input>
				</div>
				<div style="display: flex; align-items: center;">
					<span class="item-label" style="margin-bottom: 5px;">Title</span>
					<b-input class="admin-input" type="text" v-model="editingSong.title"></b-input>
				</div>
				<div style="display: flex; align-items: center;">
					<span class="item-label" style="margin-bottom: 5px;">Year</span>
					<b-input class="admin-input" type="text" v-model="editingSong.year"></b-input>
				</div>
				<div style="display: flex; align-items: center;">
					<span class="item-label" style="margin-bottom: 5px;">Tracka Genre</span>
					<b-input class="admin-input" type="text" v-model="editingSong.genre"></b-input>
				</div>
				<div style="display: flex; align-items: center;">
					<span class="item-label" style="margin-bottom: 5px;">Tracka Label</span>
					<b-input class="admin-input" type="text" v-model="editingSong.label"></b-input>
				</div>
				<!--								<div style="display: flex; align-items: center;">-->
				<!--									<span class="item-label" style="margin-bottom: 5px;">Tracka Mood</span>-->
				<!--									<b-input class="admin-input" type="text" v-model="editingSong.trackaMood"></b-input>-->
				<!--								</div>-->
				<!--								<div style="display: flex; align-items: center;">-->
				<!--									<span class="item-label" style="margin-bottom: 5px;">Tracka Tempo</span>-->
				<!--									<b-input class="admin-input" type="text" v-model="editingSong.trackaTempo"></b-input>-->
				<!--								</div>-->
				<!--								<div style="display: flex; align-items: center;">-->
				<!--									<span class="item-label" style="margin-bottom: 5px;">Tracka Distribution</span>-->
				<!--									<b-input class="admin-input" type="text" v-model="editingSong.trackaDistribution"></b-input>-->
				<!--								</div>-->
				<div style="display: flex; align-items: center;">
					<span class="item-label" style="margin-bottom: 5px;">Australian</span>
					<b-select v-model="editingSong.isAus">
						<option :value="true">Yes</option>
						<option :value="false">No</option>
					</b-select>
					<!--									<b-input class="admin-input" type="text" v-model="editingSong.isAus"></b-input>-->
				</div>
				<ActionButton style="margin-top: 20px;" buttontext="Save Changes" v-on:clicked="saveDetails" :isworking="saveDetailsWorking"></ActionButton>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import ActionButton from "@/components/ActionButton";
export default {
	name: "SongEditCard",
	components: {ActionButton},
	props: {
		trackaid: String,
	},
	data: function () {
		return {
			editingSong: {
				trackaSongId: '',
				artist: '',
				title: '',
				year: '',
				isAus: '',
				genre: '',
				label: '',
				// trackaDistribution: '',
				// trackaMood: '',
				// trackaTempo: '',
			},

			fetchDbWorking: false,
			saveDetailsWorking: false,
		}
	},
	computed: {},
	methods: {
		saveDetails() {
			if(this.saveDetailsWorking === false) {
				this.saveDetailsWorking = true
				let self = this
				let targ = '/post/save-song-details.php'
				let form = {
					trackaSongId: this.trackaid,
					songDetails: this.editingSong,
				}
				axios.post(targ, form).then(function (response) {
					let ret = response.data //response.data is returned info
					console.log(ret)
					self.getSongData(self.trackaid)
					self.saveDetailsWorking = false
				}).catch(function (error) {
					console.log(error)
					self.saveDetailsWorking = false
				});
			}
		},
		getSongData(trackaId) {
			if(this.fetchDbWorking === false) {
				this.fetchDbWorking = true
				let self = this
				let targ = "/post/get-song-details.php"
				axios.post(targ, {trackaSongId: trackaId}).then(function (response) {
					self.editingSong = response.data //response.data is returned info
					self.fetchDbWorking = false
				}).catch(function (error) {
					console.log(error)
					self.fetchDbWorking = false
				});
			}
		},
	},
	watch: {
		trackaid() {
			this.getSongData(this.trackaid)
		},
	},
	mounted() {
		this.getSongData(this.trackaid)
	}
}
</script>
<style scoped></style>