<template>
	<div>
		<div class="report-top">
			<div class="headingHold">
				<h2 id="resultsHeading">
					{{heading}}
				</h2>
			</div>

			<div style="width: 300px; display: flex; align-items: center">
				<b-form-input
						id="filter-input"
						v-model="filter"
						type="search"
						placeholder="Type to Search"
						style="font-size: 13px;"
				></b-form-input>
				<div v-if="returndata.length > 0" class="icon-excel-hold" @click="toExcel" title="Download report to Excel file"><i class="fa fa-file-excel-o"></i></div>
			</div>
		</div>

		<div class="content-container report-section">
			<b-table :fields="fields" :items="returndata" :tbody-tr-class="'trow'" :filter="filter">
				<template #cell(songPlayedTimeLocal)="data">
					<span>{{ data.item.songPlayedTimeLocal }}</span>
					<i class="fa fa-volume-up cursorPointer rowIcon" title="Listen to audio" @click="$emit('playaudioitem', data.item)"></i>
				</template>

				<template #cell(songItem.title)="data">
					<span>{{data.value}}</span>
					<div class="icon-holder">
						<i class="fa fa-building cursorPointer rowIcon" @click="drillDown('SongSpinsMarketSummary', data.item);" title="Click to view market plays"></i>
						<i v-if="$store.state.user.isAdmin !== 1" class="fa fa-angry cursorPointer rowIcon" @click="showBugReporter(data.item);" title="Report an error with this item"></i>
						<i v-if="$store.state.user.isAdmin === 1" class="fa fa-angry cursorPointer rowIcon" @click="showSongEditCard(data.item.songItem.trackaSongId);" title="Edit song details"></i>
					</div>
				</template>

				<template #cell(stationId)="data">
					<span>{{ getStationNameFromId(data.value) }}</span>
				</template>

			</b-table>
		</div>
		<SongEditOverlay v-if="$store.state.songEditCardShown"></SongEditOverlay>
		<BugReporter v-if="$store.state.bugreportshown"></BugReporter>
	</div>
</template>

<script>
import axios from "axios";
import BugReporter from "@/components/BugReporter";
import SongEditOverlay from "@/components/SongEditOverlay.vue";

export default {
	name: "DrilldownSongSpins",
	components: {SongEditOverlay, BugReporter},
	props: {
		item: Object,
		selections: Object,
	},
	data: function () {
		return {
			reportType: 'SongSpins', //for API URL
			fetching: false,
			reportTitle: 'Spins',
			heading: 'Spins',
			filter: '',
			fieldsStart: [
				{key: 'songPlayedDateLocal', label: 'Date', sortable: true},
				{key: 'songPlayedTimeLocal', label: 'Time'},
			],
			fieldsEnd: [
				{key: 'songItem.artist', label: 'Artist', sortable: true},
				{key: 'songItem.title', label: 'Title', sortable: true},
				{key: 'songItem.year', label: 'Release Year', sortable: true},
				{key: 'songPlayedDurationMinSec', label: 'Duration', sortable: true},
			],
			fields: [],
			returndata: [],
			showStationColumn: false,
		}
	},
	computed: {

	},
	methods: {
		drillDown(newreporttype, item) {
			this.$emit('switchdrilldown', {
				newreporttype: newreporttype,
				item: item
			});
		},
		getFields() {
			let f = this.fieldsStart
			if(this.showStationColumn === true) {
				f.push({key: 'stationId', label: 'Station'})
			}
			for(let g=0; g<this.fieldsEnd.length; g++) {
				f.push(this.fieldsEnd[g])
			}
			this.fields = f
		},
		getReport() {
			if(this.fetching === false) {
				this.showStationColumn = false
				let self = this
				let stnid = this.selections.stationid
				if(this.item.stationId) stnid = this.item.stationId
				let parstr = "trackaSongId=" + this.item.songItem.trackaSongId + "&daypart=ALL&dateRange=past30";
				if(stnid === 'all') {
					this.showStationColumn = true
					parstr += "&stationGroup=all&marketId="+this.selections.marketid
				}
				else parstr += "&stationId=" + stnid
				this.getFields()
				let targ = "/api-web/song-spins-drilldown.php?" + parstr
				this.$store.commit('setLastQueryUrl', targ)
				this.fetching = true
				document.title = "Fetching data..."
				axios.get(targ).then(function(response) {
					let ret = response.data; //response.data is returned info
					document.title = 'Tracka - '+self.reportTitle
					self.returndata = ret
					self.fetching = false
					self.heading = "Spins on "+self.getStationNameFromId(stnid)
					self.sendLoggingInfo(self.reportType, self.selections)
				}).catch(function(error) {
					console.log(error)
					self.fetching = false
				});
			}
		},
		toExcel() {
			var form = {};
			form.rows = this.prepareExcelTableRows(this.fields, this.returndata);
			form.layout = this.reportType;
			form.filename = this.getStationNameFromId(this.selections.stationid);
			var targ = '/post/get-excel.php';
			axios.post(targ, form).then(function (response) {
				window.location = response.data;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		item() {
			this.getReport();
		},
		fetching() {
			this.$store.commit('showLoader', this.fetching);
		}
	},
	mounted() {
		this.getReport();
	}
}
</script>

<style scoped>

</style>