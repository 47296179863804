<template>
	<div>
		<div style="display: flex;">
			<p class="admintitle">Companies</p>
			<span style="margin-left: 30px; height: 28px;" class="mark-complete-btn" @click="createNewPopup">
				Create New
			</span>
			<div style="display: inline-block; margin-left: 30px; margin-top: 2px;">
				<b-form-checkbox v-model="showInactive" switch>
					<span style="display: inline-block; font-size: 12px; color: #888; margin-top: 6px;">Show Inactive</span>
				</b-form-checkbox>
			</div>
		</div>

		<b-input type="text" v-model="searchtext" class="adminsearchbox"></b-input>

		<div class="admin-structure">

			<div class="admin-listview">
				<div v-for="li in shownObjects" :key="li.id"
					class="admin-list-row" :class="{highlighted : activeItemId === li.id}"
					@click="setActiveItem(li.id)"
				>
					<span>{{li.title}}</span>
				</div>
			</div>
			<div class="admin-infoview">
				<div v-if="activeItem">
					<div class="admin-info-section" style="display: flex; justify-content: space-between; align-items: center;">
						<span class="admin-item-title">{{activeItem.title}}</span>
					</div>
					<div class="admin-tabs-section">
						<span class="admin-tab" @click="activesubtab = 'meta'" :class="{active : activesubtab === 'meta'}">Company Info</span>
						<span class="admin-tab" @click="activesubtab = 'markets'" :class="{active : activesubtab === 'markets'}">Market Access</span>
						<span class="admin-tab" @click="activesubtab = 'reports'" :class="{active : activesubtab === 'reports'}">Custom Reports</span>
					</div>
					<div v-if="activesubtab === 'meta'">
						<div class="admin-info-section info-data">
							<p><span class="item-label">Company ID:</span> {{activeItem.id}}</p>
						</div>
						<div class="admin-info-section">
							<p class="input-label">Company Name</p>
							<b-input class="admin-input" type="text" v-model="editingReport.title"></b-input>
							<p class="input-label">Contact Name</p>
							<b-input class="admin-input" type="text" v-model="editingReport.contactname"></b-input>
							<p class="input-label">Contact Email</p>
							<b-input class="admin-input" type="text" v-model="editingReport.contactemail"></b-input>
							<p class="input-label">Status</p>
							<b-form-select v-model="editingReport.status" class="admin-input">
								<option value="active">Active</option>
								<option value="disabled">Disabled</option>
							</b-form-select>
							<ActionButton style="margin-top: 20px;" buttontext="Save Info" v-on:clicked="saveDetails" :isworking="saveDetailsWorking"></ActionButton>
						</div>
					</div>
					<div v-if="activesubtab === 'markets'">
						<div class="admin-info-section">
							<b-form-checkbox-group id="companymarkets" v-model="editingReport.marketPermIds" stacked>
								<b-form-checkbox v-for="itm in marketSearchList" :key="itm.marketId" :value="itm.marketId">
									{{itm.marketName}}
								</b-form-checkbox>
							</b-form-checkbox-group>
						</div>
						<div class="admin-info-section">
							<ActionButton buttontext="Save Info" v-on:clicked="saveDetails" :isworking="saveDetailsWorking"></ActionButton>
						</div>
					</div>
					<div v-if="activesubtab === 'reports'">
						<div class="admin-info-section">
							<b-form-checkbox-group id="companyreports" v-model="editingReport.customReportIds" stacked>
								<b-form-checkbox v-for="itm in customReportsList" :key="itm.id" :value="itm.id">
									{{itm.title}}
								</b-form-checkbox>
							</b-form-checkbox-group>
						</div>
						<div class="admin-info-section">
							<ActionButton buttontext="Save Info" v-on:clicked="saveDetails" :isworking="saveDetailsWorking"></ActionButton>
						</div>
					</div>

				</div>
			</div>

			<b-modal id="modal-create-new" ref="modal-create-new" title="Create New Company Record" hide-footer>
				<div class="modal-inner" style="padding-top: 10px; padding-bottom: 10px;">
					<p class="input-label">Company Name</p>
					<b-input class="admin-input" type="text" v-model="newReport.title"></b-input>
					<p class="input-label">Contact Name</p>
					<b-input class="admin-input" type="text" v-model="newReport.contactname"></b-input>
					<p class="input-label">Contact Email</p>
					<b-input class="admin-input" type="text" v-model="newReport.contactemail"></b-input>
					<ActionButton style="margin-top: 10px;" buttontext="Save New Company" v-on:clicked="saveNewFromPopup" :isworking="saveNewIsWorking"></ActionButton>
				</div>
			</b-modal>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import ActionButton from "@/components/ActionButton";

export default {
	name: "Companies",
	components: {ActionButton},
	props: {},
	data: function () {
		return {
			searchtext: '',
			activeItemId: 0,
			activesubtab: 'meta',

			editingReport: {
				id: 0,
				title: '',
				contactname: '',
				contactemail: '',
				status: '',
				marketPermIds: [],
				customReportIds: [],
			},

			newReport: {
				id: 0,
				contactname: '',
				contactemail: '',
			},

			showInactive: false,

			saveDetailsWorking: false,
			saveNewIsWorking: false,

		}
	},
	computed: {
		marketSearchList() {
			return this.$store.state.marketsAdminList.filter(item => item.activeFlag !== "N");
		},
		listObjects() {
			// if(this.showInactive === false) {
			// 	return this.$store.state.companiesAdminList.filter(item => item.deleted === false);
			// }
			return this.$store.state.companiesAdminList;
		},
		shownObjects() {
			if (this.searchtext.length > 0) {
				var src = this.searchtext.toLowerCase();
				return this.listObjects.filter(item =>
					(item.title.toLowerCase().includes(src) || item.contactname.toLowerCase().includes(src))
				);
			}
			return this.listObjects; //return all if limit not set
		},
		activeItem() {
			return this.$store.state.companiesAdminList.find(item => item.id === this.activeItemId);
		},
		customReportsList() {
			return this.$store.state.reportsAdminList.filter(item => item.iscustom === true);
		}
	},
	methods: {
		createNewPopup() {
			this.newReport = {
				id: 0,
				title: '',
				contactname: '',
				contactemail: '',
			};
			this.$refs['modal-create-new'].show();
		},
		saveNewFromPopup() {
			if(this.newReport.title.length > 0) {
				if(this.saveNewIsWorking === false) {
					var form = {};
					form.editingReport = this.newReport;
					this.saveNewIsWorking = true;
					var self = this;
					var targ = '/post/admin/save-company-details.php';
					axios.post(targ, form).then(function (response) {
						var ret = response.data; //response.data is returned info
						self.$store.commit('setFromAdminReturns', ret);
						self.$refs['modal-create-new'].hide();
						self.saveNewIsWorking = false;
					}).catch(function (error) {
						console.log(error);
						self.saveNewIsWorking = false;
					});
				}
			}
			else {
				alert("Please enter a company name");
			}
		},
		setActiveItem(id) {
			this.activeItemId = id;
			this.editingReport.id = id;
			this.editingReport.title = this.activeItem.title;
			this.editingReport.contactname = this.activeItem.contactname;
			this.editingReport.contactemail = this.activeItem.contactemail;
			this.editingReport.status = this.activeItem.status;
			this.editingReport.marketPermIds = this.activeItem.marketPermIds;
			this.editingReport.customReportIds = this.activeItem.customReportIds;
		},
		saveDetails() {
			if(this.saveDetailsWorking === false) {
				var form = {};
				form.editingReport = this.editingReport;
				this.saveDetailsWorking = true;
				var self = this;
				var targ = '/post/admin/save-company-details.php';
				axios.post(targ, form).then(function (response) {
					var ret = response.data; //response.data is returned info
					self.$store.commit('setFromAdminReturns', ret);
					self.saveDetailsWorking = false;
				}).catch(function (error) {
					console.log(error);
					self.saveDetailsWorking = false;
				});
			}
		},
	},
	watch: {
		listObjects() {
			if(this.activeItemId === 0) {
				this.setActiveItem(this.listObjects[0].id);
			}
		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>