<template>
	<div class="">
		<DrilldownHolder v-if="drilldownOverlayShow" :drilldownreporttype="drilldownReportType"
			:drilldownitem="drilldownitem" v-on:closedrilldown="drilldownOverlayShow = false"
			:selections="selections"
		></DrilldownHolder>
		<div v-if="started" class="content-container report-section" :class="{sectionhide : drilldownOverlayShow === true}">
			<div class="report-top">
				<div class="headingHold">
					<h2 id="resultsHeading">
						{{heading}}
					</h2>
					<p id="resultsSubheading">{{subheading}}</p>
				</div>

				<div style="width: 300px; display: flex; align-items: center">
					<b-form-input
							id="filter-input"
							v-model="filter"
							type="search"
							placeholder="Type to Search"
							style="font-size: 13px;"
					></b-form-input>
					<div class="icon-excel-hold" v-if="returndata.length > 0" @click="toExcel" title="Download report to Excel file"><i class="fa fa-file-excel-o"></i></div>
				</div>
			</div>

			<b-table :fields="fields" :items="returndata" :tbody-tr-class="rowClass" :filter="filter">
				<template #cell(songItem.title)="data">
					<span>{{data.value}}</span>
					<div class="icon-holder" v-if="data.item.isTOH !== true">
						<i class="fa fa-clock cursorPointer rowIcon" @click="drillDown('SongSpins', data.item);" :title="'Click to view other '+data.item.stationName+' play times'"></i>
						<i class="fa fa-building cursorPointer rowIcon" @click="drillDown('SongSpinsMarketSummary', data.item);" title="Click to view market plays"></i>
						<i v-if="$store.state.user.isAdmin !== 1" class="fa fa-angry cursorPointer rowIcon" @click="showBugReporter(data.item);" title="Report an error with this item"></i>
						<i v-if="$store.state.user.isAdmin === 1" class="fa fa-angry cursorPointer rowIcon" @click="showSongEditCard(data.item.songItem.trackaSongId);" title="Edit song details"></i>
					</div>
				</template>
			</b-table>

		</div>
		<BugReporter v-if="$store.state.bugreportshown"></BugReporter>
		<SongEditOverlay v-if="$store.state.songEditCardShown"></SongEditOverlay>
	</div>
</template>

<script>
import axios from "axios";
import BugReporter from "@/components/BugReporter";
import DrilldownHolder from "@/components/DrilldownHolder";
import SongEditOverlay from "@/components/SongEditOverlay.vue";
export default {
	name: "NewTracks",
	components: {
		SongEditOverlay,
		DrilldownHolder,
		BugReporter,
	},
	props: {
		selections: Object,
	},
	data: function () {
		return {
			showSelects: {
				market: true,
				brand: true,
				period: true,
				property: false,
				daypart: true,
				logdate: false,
				startdate: false,
				enddate: false,
			},
			reportType: 'NewTracks', //for API URL
			fetching: false,
			started: false,
			reportTitle: "New Tracks for brand",

			heading: 'New Tracks for brand',
			subheading: 'Tracks that have played in this period that were not played in previous period',

			returndata: [],
			fields: [
				{key: 'songItem.artist', label: 'Artist', sortable: true},
				{key: 'songItem.title', label: 'Title', sortable: true},
				// {key: 'label', label: 'Label', sortable: true},
				{key: 'rotation', label: 'Avg Rotation (Hrs)', sortable: true},
				{key: 'plays', label: 'Plays', sortable: true},
				{key: 'songItem.year', label: 'Release Year', sortable: true},
			],
			filter: '',
			rowClass: 'trow',

			drilldownOverlayShow: false,
			drilldownReportType: '',
			drilldownitem: null,
		}
	},
	computed: {

	},
	methods: {
		drillDown(reporttype, item) {
			this.drilldownReportType = reporttype
			this.drilldownitem = item
			this.drilldownOverlayShow = true
		},
		getReport() {
			if(this.fetching === false) {
				this.drilldownOverlayShow = false; //force hide the drilldown reports
				if(this.selections.stationid === null || this.selections.marketid === null
					|| this.selections.period === null || this.selections.daypart === null) {
					return false
				}
				let self = this
				//manually override any selections that are required here
				let params = 'stationId='+this.selections.stationid+'&period='+this.selections.period+"&daypartName="+this.selections.daypart
				let targ = '/api-web/new-tracks-for-station.php?'+params

				this.$store.commit('setLastQueryUrl', targ)
				this.fetching = true
				document.title = "Fetching data..."
				axios.get(targ).then(function(response) {
					let ret = response.data; //response.data is returned info
					document.title = 'Tracka - '+self.reportTitle
					self.returndata = ret.tracks
					self.fetching = false
					self.started = true //first one has come back
					self.heading = "New Tracks for " + self.getStationNameFromId(self.selections.stationid)
					self.sendLoggingInfo(self.reportType, self.selections)
				}).catch(function(error) {
					console.log(error)
					self.fetching = false
				});
			}
		},
		toExcel() {
			var form = {};
			form.rows = this.prepareExcelTableRows(this.fields, this.returndata)
			form.layout = this.reportType
			form.filename = this.getStationNameFromId(this.selections.stationid)
			var targ = '/post/get-excel.php'
			axios.post(targ, form).then(function (response) {
				window.location = response.data
			}).catch(function (error) {
				console.log(error)
			});
		},
		showBugReporter(item) {
			this.$store.commit('setBugReportItem', item)
			this.$store.commit('showBugReporter', true)
		},
	},
	watch: {
		fetching() {
			this.$store.commit('showLoader', this.fetching)
		}
	},
	mounted() {
		this.checkFetchUserAppData(); //triggers fetch items from kev db for usage in front end for user
		this.$store.commit('setMusicDropdownItems', this.showSelects)
		document.title = 'Tracka - '+this.reportTitle
	}
}
</script>

<style scoped>


</style>