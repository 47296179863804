<template>
	<div>
		<div style="display: flex;">
			<p class="admintitle">Stations</p>
			<span style="margin-left: 30px; height: 28px;" class="mark-complete-btn" @click="refreshStationsFromACR">
				<span v-if="fetchingFromAcr === true">Working...</span>
				<span v-else>Refresh From ACR</span>
			</span>
		</div>

		<b-input type="text" v-model="searchtext" class="adminsearchbox"></b-input>

		<div class="admin-structure">

			<div class="admin-listview">
				<div v-for="li in shownObjects" :key="li.stationId"
					class="admin-list-row" :class="{highlighted : activeItemId === li.stationId}"
					@click="setActiveItem(li.stationId)"
				>
					<span>{{li.stationName}} ({{li.acrStreamCity}}) [{{li.acrStreamId}}: {{li.acrStreamStatus}}]</span>
				</div>
			</div>
			<div class="admin-infoview">
				<div v-if="activeItem">
					<div class="admin-info-section">
						<span class="admin-item-title">{{activeItem.stationName}}</span>
					</div>
					<div class="admin-tabs-section">
						<span class="admin-tab" @click="activesubtab = 'meta'" :class="{active : activesubtab === 'meta'}">Station Meta</span>
						<span class="admin-tab" @click="activesubtab = 'assignments'" :class="{active : activesubtab === 'assignments'}">Market Assignments</span>
					</div>
					<div v-if="activesubtab === 'meta'">
						<div class="admin-info-section info-data">
							<p><span class="item-label">Tracka Station ID:</span> {{activeItem.stationId}}</p>
							<p><span class="item-label">City:</span> {{activeItem.acrStreamCity}}</p>
							<p><span class="item-label">ACR ID:</span> {{activeItem.acrStreamId}}</p>
							<p><span class="item-label">ACR Status:</span> {{activeItem.acrStreamStatus}}</p>
							<p><span class="item-label">First Record:</span> {{activeItem.firstRecordDate}}</p>
							<p><span class="item-label">UpdateTsUTC:</span> {{activeItem.stationUpdateTsUtc}}</p>
						</div>
						<div class="admin-info-section">
							<p class="input-label">Station Name</p>
							<b-input class="admin-input" type="text" v-model="editingStation.stationName"></b-input>
							<p class="input-label">Timezone</p>
							<b-form-select v-model="editingStation.stationTz" class="admin-input">
								<option v-for="tz in timezones" :key="tz.timezone" :value="tz.timezone">{{tz.timezone}}</option>
							</b-form-select>
							<p class="input-label">Station is Active</p>
							<b-form-select v-model="editingStation.activeFlag" class="admin-input">
								<option value="Y">Yes</option>
								<option value="N">No</option>
							</b-form-select>
							<p class="input-label">Stream Protocol</p>
							<b-input class="admin-input" type="text" v-model="editingStation.streamProtocol"></b-input>
							<p class="input-label">Stream URL</p>
							<b-input class="admin-input" type="text" v-model="editingStation.streamUrl"></b-input>
							<p class="input-label">Audio Should Be Recorded</p>
							<b-form-select v-model="editingStation.audioRecording" class="admin-input">
								<option value="1">Yes</option>
								<option value="0">No</option>
							</b-form-select>
							<ActionButton style="margin-top: 20px;" buttontext="Save Metadata" v-on:clicked="saveStationDetails" :isworking="saveStationDetailsWorking"></ActionButton>
						</div>
					</div>
					<div v-if="activesubtab === 'assignments'" style="padding-top: 10px;">
						<span style="margin-left: 20px; margin-top: 10px; margin-bottom: 10px;" class="kbtn" v-b-modal.modal-market-search>
							<span>Add To Market</span>
						</span>
						<Chip v-for="mkt in activeStationAssignedMktIds" :key="mkt.marketId"
							:chipid="mkt.marketId" :chipname="mkt.marketName"
							v-on:remove="removeMarket(mkt.marketId)"
						></Chip>
						<div class="admin-info-section">
							<ActionButton buttontext="Save Mappings" v-on:clicked="saveMappings" :isworking="saveStationDetailsWorking"></ActionButton>
						</div>
					</div>

				</div>
			</div>

			<b-modal id="modal-market-search" ref="modal-market-search" title="Market Search" hide-footer>
				<div class="modal-inner" style="padding-top: 10px; padding-bottom: 10px;">
					<Autocompleter :searchlist="marketSearchList"
							idfldname="marketId" textfldname="marketName" v-on:update="addMarketToStationMaplist($event)"></Autocompleter>
				</div>
			</b-modal>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import Chip from "@/components/Chip";
import Autocompleter from "@/components/Autocompleter";
import ActionButton from "@/components/ActionButton";

export default {
	name: "Stations",
	components: {ActionButton, Autocompleter, Chip},
	props: {},
	data: function () {
		return {
			searchtext: '',
			activeItemId: 0,
			activesubtab: 'meta',

			editingStation: {
				stationId: 0,
				stationName: '',
				stationTz: '',
				activeFlag: 'Y',
				streamProtocol: '',
				streamUrl: '',
				audioRecording: 0,
			},

			activeStationAssignedMktIds: [],

			saveStationDetailsWorking: false,
			fetchingFromAcr: false,
		}
	},
	computed: {
		marketSearchList() {
			return this.$store.state.marketsAdminList.filter(item => item.activeFlag !== "N");
		},
		listObjects() {
			return this.$store.state.stationsAdminList;
		},
		shownObjects() {
			if (this.searchtext.length > 0) {
				var src = this.searchtext.toLowerCase();
				return this.listObjects.filter(item =>
					(item.stationName.toLowerCase().includes(src) || item.acrStreamCity.toLowerCase().includes(src)
						|| item.acrStreamId.toString().includes(src) || item.acrStreamStatus.toLowerCase().includes(src)
					)
				);
			}
			return this.listObjects; //return all if limit not set
		},
		timezones() {
			return this.$store.state.timezonelist;
		},
		activeItem() {
			return this.$store.state.stationsAdminList.find(item => item.stationId === this.activeItemId);
		},
		mappedMarketsForActiveStation() {
			if(this.activeItemId === 0) return [];
			return this.$store.state.stationMarketMappings.filter(item => item.stationId === this.activeItemId);
		},
	},
	methods: {
		refreshStationsFromACR() {
			if(this.fetchingFromAcr === false) {
				this.fetchingFromAcr = true
				let self = this
				let targ = '/post/admin/refresh-stations-from-acr.php'
				axios.get(targ).then(function (response) {
					let ret = response.data //response.data is returned info
					console.log(ret)
					self.fetchPublicSelectData()
					self.fetchingFromAcr = false
				}).catch(function (error) {
					console.log(error);
					self.fetchingFromAcr = false
				});
			}
		},
		setActiveItem(id) {
			this.activeItemId = id;
			this.editingStation.stationId = id;
			this.editingStation.stationName = this.activeItem.stationName;
			this.editingStation.stationTz = this.activeItem.stationTz;
			this.editingStation.activeFlag = this.activeItem.activeFlag;
			this.editingStation.streamProtocol = this.activeItem.streamProtocol;
			this.editingStation.streamUrl = this.activeItem.streamUrl;
			this.editingStation.audioRecording = this.activeItem.audioRecording;
			this.activeStationAssignedMktIds = this.mappedMarketsForActiveStation;
		},
		saveStationDetails() {
			if(this.saveStationDetailsWorking === false) {
				let form = {
					"stationId": this.editingStation.stationId,
					"stationName": this.editingStation.stationName,
					"stationTz": this.editingStation.stationTz,
					"activeFlag": this.editingStation.activeFlag,
					"streamProtocol": this.editingStation.streamProtocol,
					"streamUrl": this.editingStation.streamUrl,
					"audioRecording": this.editingStation.audioRecording,
				};
				this.saveStationDetailsWorking = true;
				let self = this;
				let targ = '/post/admin/save-station.php'
				axios.post(targ, form).then(function (response) {
					let ret = response.data; //response.data is returned info
					console.log(ret);
					self.fetchPublicSelectData()
					self.saveStationDetailsWorking = false;
				}).catch(function (error) {
					console.log(error);
					self.saveStationDetailsWorking = false;
				});
			}
		},
		removeMarket(removeMktId) {
			this.activeStationAssignedMktIds = this.activeStationAssignedMktIds.filter(item => item.marketId !== removeMktId);
		},
		saveMappings() {
			if(this.saveStationDetailsWorking === false) {
				let mktids = [];
				for (let i = 0; i < this.activeStationAssignedMktIds.length; i++) {
					mktids.push(this.activeStationAssignedMktIds[i].marketId); //stationId for stations
				}
				this.saveStationDetailsWorking = true;
				let self = this;
				let form = {
					stationId: this.editingStation.stationId,
					marketIdList: mktids
				};
				let targ = '/post/admin/save-station-mapping.php'
				axios.post(targ, form).then(function (response) {
					var ret = response.data; //response.data is returned info
					console.log(ret);
					self.fetchAdminData()
					self.saveStationDetailsWorking = false;
				}).catch(function (error) {
					console.log(error);
					self.saveStationDetailsWorking = false;
				});
			}
		},
		addMarketToStationMaplist(mktob) {
			if(mktob.marketId > 0) {
				var alreadyinarray = this.activeStationAssignedMktIds.find(item => item.marketId === mktob.marketId);
				if(!alreadyinarray) {
					var mktToAdd = this.$store.state.marketsAdminList.find(item => item.marketId === mktob.marketId);
					this.activeStationAssignedMktIds.push(mktToAdd);
					this.$refs['modal-market-search'].hide();
				}
			}
		},
	},
	watch: {
		listObjects() {
			if(this.activeItemId === 0) {
				this.setActiveItem(this.listObjects[0].stationId);
			}
		},
		mappedMarketsForActiveStation() {
			this.activeStationAssignedMktIds = this.mappedMarketsForActiveStation;
		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>