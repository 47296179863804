<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata.length > 0" width="99%" height="100%" type="donut" :options="chartoptions" :series="chartdata"></apexchart>
	</div>
</template>

<script>
export default {
	name: "RotationCategoryBreakdown",
	components: {},
	props: {
		chartdata: Array,
		chartcategories: Array,
	},
	data: function () {
		return {
			chartoptions: {
				colors: this.$store.state.chartColours,
				chart: {
					type: 'donut',
					events: {
						dataPointSelection: function (event, chartContext, config) {
							var propvalname = config.w.globals.labels[config.dataPointIndex];
							this.$emit('songlistdrill', propvalname);
						}.bind(this),
					}
				},
				legend: {
					position: 'bottom',
					formatter: (seriesName) => seriesName,
				},
				labels: [],
				plotOptions: {
					pie: {
						donut: {
							size: '40%'
						}
					}
				},
				dataLabels: {
					enabled: false,
				},
				tooltip: {
					custom: function({series, seriesIndex, w}) {
						return '<div class="wc-tooltip">' +
							'<div class="wc-tooltip-top">'+w.globals.labels[seriesIndex]+'</div>'+
							'<div class="wc-tooltip-body">' +
							'<div class="wc-tooltip-line">' +
								'<span class="wc-tooltip-color-circle" style="background-color: '+this.$store.state.chartColours[seriesIndex]+'"></span>' +
								'<span class="boldme">'+series[seriesIndex]+'%</span>' +
							'</div>'+
								'<span style="display:inline-block;margin-top: 5px;">Click to view song list</span></div>'+
							'</div>'
					}.bind(this),
				},
			},

		}
	},
	computed: {

	},
	methods: {
		updateCategories() { //need to do this manually as data is nested
			this.chartoptions = {...this.chartoptions, ...{
					labels: this.chartcategories
			}};
		},
	},
	watch: {
		chartcategories() {
			this.updateCategories();
		},
	},
	mounted() {
		this.updateCategories();
	}
}
</script>

<style scoped>

</style>