<template>
	<div id="errorblanket">
		<div id="error-box-hold">
			<div id="error-box">
				<div id="error-box-top">
					<p class="error-box-heading">Notify us of an error</p>
					<div class="error-exit" @click="closeOverlay">
						<i class="fa fa-times"></i>
					</div>
				</div>
				<div id="error-form-hold">
					<input type="hidden" id="error-trackaid" :value="bugTrackaId" />
					<p id="error-existInfo">{{bugItem.songItem.artist}} - {{bugItem.songItem.title}} - {{bugItem.songItem.year}}</p>
					<p class="boldme" >Error Type</p>
					<b-form-group>
						<b-form-radio v-model="errorType" value="WrongArtistOrTitle">Wrong Artist or Title</b-form-radio>
						<b-form-radio v-model="errorType" value="WrongYear">Wrong Year</b-form-radio>
						<b-form-radio v-model="errorType" value="DuplicateEntry">Duplicate Entry</b-form-radio>
						<b-form-radio v-model="errorType" value="Other">Other</b-form-radio>
					</b-form-group>
					<p class="boldme">Optional Notes/Info</p>
					<textarea id="error-user-notes" v-model="usernotes"></textarea>
					<button @click="sendErrorReport" type="button" class="errbtn">Submit</button>
					<button @click="closeOverlay" type="button" class="errbtn errbtn-cancel">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "BugReporter",
	components: {},
	props: {

	},
	data: function () {
		return {
			usernotes: '',
			errorType: null,
		}
	},
	computed: {
		bugTrackaId() {
			if(this.$store.state.bugreportitem.trackaId) {
				return this.$store.state.bugreportitem.trackaId;
			}
			return "";
		},
		bugItem() {
			return this.$store.state.bugreportitem;
		},
	},
	methods: {
		sendErrorReport() {
			if(this.errorType && this.bugItem)
			var form = {};
			form.trackaId = this.bugTrackaId;
			let existingInfo = this.bugItem.songArtists+" - "+this.bugItem.songTitle+" - "+this.bugItem.leastReleaseYear;
			existingInfo = existingInfo.replace(";","");
			existingInfo = existingInfo.replace("/","");
			existingInfo = existingInfo.replace("\\","");
			form.existingInfo = existingInfo;
			form.errorType = this.errorType;
			form.notes = this.usernotes;
			form.lastQuery = this.$store.state.lastQueryUrl;
			var targ = '/post/report-error.php';
			var self = this;
			axios.post(targ, form).then(function (response) {
				window.alert(response.data.message);
				self.closeOverlay();
			}).catch(function (error) {
				console.log(error);
			});
		},
		closeOverlay() {
			this.$store.commit('showBugReporter', false);
			this.$store.commit('setBugReportItem', null);
		},
	},
	watch: {
		bugItem() {
			this.usernotes = '';
			this.errorType = null;
		}
	},
	mounted() {

	}
}
</script>

<style scoped>
#errorblanket {
	background:rgba(0,0,0,0.6);
	z-index: 9999;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
}
#error-box-hold {
	text-align: left;
	margin: 50px auto;
	padding: 0;
	background: #FFF;
	width: 500px;
}
#error-box-top {
	background: #574099;
	color: #FFF;
	padding: 8px 15px;
	position: relative;
}
#error-form-hold {
	padding: 20px 50px 40px;
	font-size: 14px;
}
#error-form-hold p {
	margin-bottom: 5px;
	line-height: 1.5;
}
#error-form-hold label {
	margin-left: 5px;
	line-height: 1.5;
}
.error-box-heading {
	color: #FFF;
	margin: 0;
}
.error-exit {
	position: absolute;
	top: 8px;
	right: 10px;
	color: #FFF;
	cursor: pointer;
}
.error-exit:hover i {
	color: #CCC;
}
#error-user-notes {
	width: 100%;
	height: 100px;
	margin-bottom: 10px;
	font-family: arial, Sans-Serif;
	padding: 5px;
}
.errbtn {
	border: none;
	padding: 10px 20px;
	color: #FFF;
	background: #574099;
	font-size: 16px;
	cursor: pointer;
	margin-right: 15px;
	transition: opacity 0.3s ease-in-out;
}
.errbtn-cancel {
	background: #888;
}
.errbtn:hover {
	opacity: 0.9;
}
.custom-control {
	display: flex;
	align-items: center;
}

</style>